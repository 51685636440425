import React, { useState } from "react";
import "./StringCaseConverter.css";

const StringCaseConverter = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");

  const convertCase = (type) => {
    switch (type) {
      case "lower":
        setOutput(input.toLowerCase());
        break;
      case "upper":
        setOutput(input.toUpperCase());
        break;
      case "title":
        setOutput(
          input.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
          ),
        );
        break;
      case "camel":
        setOutput(
          input
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
              index === 0 ? word.toLowerCase() : word.toUpperCase(),
            )
            .replace(/\s+/g, ""),
        );
        break;
      case "snake":
        setOutput(input.toLowerCase().replace(/\s+/g, "_"));
        break;
      case "kebab":
        setOutput(input.toLowerCase().replace(/\s+/g, "-"));
        break;
      default:
        setOutput(input);
    }
  };

  return (
    <div className="string-case-converter">
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter text here..."
      />
      <div className="button-group">
        <button onClick={() => convertCase("lower")}>lowercase</button>
        <button onClick={() => convertCase("upper")}>UPPERCASE</button>
        <button onClick={() => convertCase("title")}>Title Case</button>
        <button onClick={() => convertCase("camel")}>camelCase</button>
        <button onClick={() => convertCase("snake")}>snake_case</button>
        <button onClick={() => convertCase("kebab")}>kebab-case</button>
      </div>
      <textarea
        value={output}
        readOnly
        placeholder="Converted text will appear here..."
      />
    </div>
  );
};

export default StringCaseConverter;
