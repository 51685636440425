import React, { useState } from "react";
import "./JsonToCode.css";
import Button from "../../../components/Button/Button";
import Textarea from "../../../components/Textarea/Textarea";
import Select from "../../../components/Select/Select";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

const JsonToCode = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [codeOutput, setCodeOutput] = useState("");

  const handleBack = () => {
    setJsonInput("");
    setLanguage("javascript");
    setCodeOutput("");
  };

  const languageOptions = [
    { value: "javascript", label: "JavaScript" },
    { value: "python", label: "Python" },
    { value: "java", label: "Java" },
  ];

  const convertJsonToCode = () => {
    try {
      const jsonObj = JSON.parse(jsonInput);
      let code = "";

      switch (language) {
        case "javascript":
          code = `const data = ${JSON.stringify(jsonObj, null, 2)};`;
          break;
        case "python":
          code = `data = ${JSON.stringify(jsonObj, null, 2).replace(/"([^"]+)":/g, "$1:")}`;
          break;
        case "java":
          // This is a basic conversion. A more robust solution would generate proper Java classes.
          code = `Map<String, Object> data = new HashMap<>() {{
  ${Object.entries(jsonObj)
    .map(([key, value]) => `put("${key}", ${JSON.stringify(value)});`)
    .join("\n  ")}
}};`;
          break;
        default:
          code = "Unsupported language";
      }

      setCodeOutput(code);
    } catch (error) {
      setCodeOutput("Invalid JSON input");
    }
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={jsonInput || codeOutput ? handleBack : null}
        title="JSON to Code Converter"
      />

      <Textarea
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder="Paste your JSON here..."
      />
      <Select
        options={languageOptions}
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        placeholder="Select Language"
      />
      <Button onClick={convertJsonToCode}>Convert to Code</Button>
      {codeOutput && (
        <Textarea
          value={codeOutput}
          readOnly
          placeholder="Code output will appear here..."
        />
      )}
    </div>
  );
};

export default JsonToCode;
