import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

const JsonUnstringifier = () => {
  const [inputString, setInputString] = useState("");
  const [parsedJson, setParsedJson] = useState("");

  const handleBack = () => {
    setInputString("");
    setParsedJson("");
  };

  const handleInputChange = (e) => {
    setInputString(e.target.value);
  };

  const unstringifyJson = () => {
    try {
      const json = JSON.parse(inputString);
      setParsedJson(JSON.stringify(json, null, 2));
    } catch (error) {
      alert("Invalid stringified JSON input");
    }
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={inputString || parsedJson ? handleBack : null}
        title="JSON Unstringifier"
      />

      <Textarea
        value={inputString}
        onChange={handleInputChange}
        rows="4"
        cols="50"
        placeholder="Enter Stringified JSON here"
      />
      <Button onClick={unstringifyJson}>Parse JSON</Button>
      {parsedJson && (
        <div>
          <h3>Parsed JSON:</h3>
          <pre>{parsedJson}</pre>
        </div>
      )}
    </div>
  );
};

export default JsonUnstringifier;
