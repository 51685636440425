import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./SignPDF.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SignPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [signedPdfBlob, setSignedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [signatureText, setSignatureText] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleSignatureTextChange = (e) => {
    setSignatureText(e.target.value);
  };

  const handlePositionChange = (e) => {
    const { name, value } = e.target;
    setPosition((prevPosition) => ({
      ...prevPosition,
      [name]: parseFloat(value),
    }));
  };

  const handleSignPDF = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);
    formData.append("signatureText", signatureText);
    formData.append("position", JSON.stringify(position));

    try {
      const response = await axios.post(`${baseUrl}/pdf/sign`, formData, {
        responseType: "blob",
      });
      setSignedPdfBlob(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      console.error("Error signing PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadSignedPdf = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(signedPdfBlob);
    link.setAttribute("download", "signed_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Sign PDF</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="text"
        value={signatureText}
        onChange={handleSignatureTextChange}
        placeholder="Signature Text"
      />
      <Input
        type="number"
        name="x"
        value={position.x}
        onChange={handlePositionChange}
        placeholder="X Position"
      />
      <Input
        type="number"
        name="y"
        value={position.y}
        onChange={handlePositionChange}
        placeholder="Y Position"
      />
      <Button onClick={handleSignPDF} disabled={!pdfFile || isLoading}>
        Sign PDF
      </Button>
      {signedPdfBlob && (
        <div>
          <Document file={signedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadSignedPdf}>Download Signed PDF</Button>
        </div>
      )}
    </div>
  );
};

export default SignPDF;
