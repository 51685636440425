import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./RotatePDFPages.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RotatePDFPages = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [rotatedPdfBlob, setRotatedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumbers, setPageNumbers] = useState("");
  const [degrees, setDegrees] = useState("");

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handlePageNumbersChange = (e) => {
    setPageNumbers(e.target.value);
  };

  const handleDegreesChange = (e) => {
    setDegrees(e.target.value);
  };

  const handleRotatePages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);
    formData.append("pageNumbers", `[${pageNumbers}]`);
    formData.append("degrees", degrees);

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/rotate-pages`,
        formData,
        { responseType: "blob" },
      );
      setRotatedPdfBlob(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      console.error("Error rotating PDF pages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadRotatedPdf = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(rotatedPdfBlob);
    link.setAttribute("download", "rotated_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Rotate PDF Pages</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="text"
        value={pageNumbers}
        onChange={handlePageNumbersChange}
        placeholder="Page Numbers (comma separated)"
      />
      <Input
        type="number"
        value={degrees}
        onChange={handleDegreesChange}
        placeholder="Degrees of Rotation"
      />
      <Button onClick={handleRotatePages} disabled={!pdfFile || isLoading}>
        Rotate Pages
      </Button>
      {rotatedPdfBlob && (
        <div>
          <Document file={rotatedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadRotatedPdf}>Download Rotated PDF</Button>
        </div>
      )}
    </div>
  );
};

export default RotatePDFPages;
