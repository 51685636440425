import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./CombinePDFs.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:2000"
    : "https://devessentialsbackend.onrender.com";
const CombinePDFs = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const handleFileChange = (e) => {
    setPdfFiles([...e.target.files]);
  };

  const handleCombinePDFs = async () => {
    setIsLoading(true);
    const formData = new FormData();
    pdfFiles.forEach((file) => formData.append("pdfFiles", file));

    try {
      const response = await axios.post(`${baseUrl}/pdf/combine`, formData, {
        responseType: "blob",
      });
      setCombinedPdfBlob(
        new Blob([response.data], { type: "application/pdf" }),
      );
    } catch (error) {
      console.error("Error combining PDFs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCombinedPdf = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(combinedPdfBlob);
    link.setAttribute("download", "combined_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div className="pdf-utility-container">
      <h2>Combine PDFs</h2>
      <Input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        multiple
        // className="file-Input"
      />
      <Button
        onClick={handleCombinePDFs}
        disabled={pdfFiles.length === 0 || isLoading}
      >
        Combine PDFs
      </Button>
      {combinedPdfBlob && (
        <div>
          <div className="pdf-container">
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={combinedPdfBlob}
              className="pdf-document"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page">
                  <Page key={index} pageNumber={index + 1} width={100} />
                </div>
              ))}
            </Document>
          </div>
          <Button onClick={downloadCombinedPdf}>Download Combined PDF</Button>
        </div>
      )}
    </div>
  );
};

export default CombinePDFs;
