import React, { useState, useRef } from "react";
import "./Input.css";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const Input = ({ type = "text", onChange, ...props }) => {
  const [fileLabel, setFileLabel] = useState("No file chosen");
  const [isDragging, setIsDragging] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const handleFileChange = (event) => {
    const files = event.target.files;
    updateFileLabel(files);
    if (onChange) {
      onChange(event);
    }
  };

  const updateFileLabel = (files) => {
    if (files && files.length > 1) {
      setFileLabel(`${files.length} files selected`);
    } else {
      setFileLabel(files[0] ? files[0].name : "No file chosen");
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    updateFileLabel(files);

    if (inputRef.current) {
      inputRef.current.files = files;
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  };

  const inputVariants = {
    focus: {
      scale: 1.02,
      boxShadow: "0 0 0 2px #4299e1",
      transition: { duration: 0.2 },
    },
  };

  if (type === "file") {
    return (
      <div
        className={`input-container file-input-container ${isDragging ? "dragging" : ""}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <motion.input
          ref={inputRef}
          type="file"
          id="file"
          variants={inputVariants}
          whileFocus="focus"
          className="input-element file-input"
          onChange={handleFileChange}
          {...props}
          placeholder=""
        />
        <label htmlFor="file" className="file-input-label">
          <div className="file-input-text">
            {isDragging ? "Drop files here" : fileLabel}
            <span className="file-input-plus">+</span>
          </div>
        </label>
      </div>
    );
  }

  return (
    <div
      className={`input-container ${isFocused || (inputRef.current && inputRef.current.value) ? "focused" : ""}`}
    >
      {/* <label className="floating-label">{props.placeholder}</label> */}
      <input
        ref={inputRef}
        type={type}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="input-element"
        {...props}
        placeholder=" "
      />
      {props.placeholder && (
        <label className="floating-label">{props.placeholder}</label>
      )}
      {/* {props.placeholder && <span className="outlined">{props.placeholder}</span>} */}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Input;
