import React, { useState } from "react";
import "./UrlEncoderDecoder.css";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import PropTypes from "prop-types";

const UrlEncoderDecoder = ({ encodeLabel, decodeLabel }) => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");

  const handleBack = () => {
    setInput("");
    setOutput("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleEncode = () => {
    setOutput(encodeURIComponent(input));
  };

  const handleDecode = () => {
    setOutput(decodeURIComponent(input));
  };

  return (
    <div className="bodybeautifier">
      <BackButton
        onBack={input || output ? handleBack : null}
        title="URL Encoder/Decoder"
      />

      <Textarea
        className="Textarea"
        value={input}
        onChange={handleInputChange}
        placeholder="Enter text here..."
      ></Textarea>
      <Button onClick={handleEncode}>{encodeLabel || "Encode"}</Button>
      <Button onClick={handleDecode}>{decodeLabel || "Decode"}</Button>
      <Textarea className="Textarea" value={output} readOnly></Textarea>
    </div>
  );
};

UrlEncoderDecoder.propTypes = {
  encodeLabel: PropTypes.string,
  decodeLabel: PropTypes.string,
};

export default UrlEncoderDecoder;
