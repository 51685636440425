import React, { useState } from "react";
import axios from "axios";
import "./BackgroundRemover.css";
import "../image.css";
import BackButton from "../../../components/BackButton/BackButton";

const BackgroundRemover = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [backgroundRemovedImageUrl, setBackgroundRemovedImageUrl] =
    useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadError, setUploadError] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBack = () => {
    setSelectedFile(null);
    setBackgroundRemovedImageUrl("");
    setIsProcessing(false);
    setUploadError("");
  };

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleBackgroundRemoval = async () => {
    const formData = new FormData();
    formData.append("imageFile", selectedFile);

    setIsProcessing(true);

    try {
      const response = await axios.post(
        `${baseUrl}/image/removeBackground`,
        formData,
        {
          responseType: "blob", // Important to handle binary data
        },
      );

      const url = URL.createObjectURL(response.data);
      setBackgroundRemovedImageUrl(url);
      setUploadError("");
    } catch (error) {
      setUploadError("Failed to remove background from image.");
      console.error("Upload Error:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="image-utility-container">
      <BackButton
        onBack={selectedFile ? handleBack : null}
        title="Background Remover"
      />

      {!selectedFile ? (
        <input type="file" accept="image/*" onChange={handleFileChange} />
      ) : (
        <div>{selectedFile.name}</div>
      )}

      {selectedFile && !isProcessing && (
        <button onClick={handleBackgroundRemoval}>Remove Background</button>
      )}

      {isProcessing && <div className="spinner"></div>}

      {backgroundRemovedImageUrl && (
        <div>
          <img src={backgroundRemovedImageUrl} alt="Background Removed" />
        </div>
      )}

      {uploadError && <p className="error-message">{uploadError}</p>}
    </div>
  );
};

export default BackgroundRemover;
