import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./UnlockPDF.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UnlockPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [unlockedPdfBlob, setUnlockedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUnlockPDF = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);
    formData.append("password", password);

    try {
      const response = await axios.post(`${baseUrl}/pdf/unlock`, formData, {
        responseType: "blob",
      });
      setUnlockedPdfBlob(
        new Blob([response.data], { type: "application/pdf" }),
      );
    } catch (error) {
      console.error("Error unlocking PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadUnlockedPdf = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(unlockedPdfBlob);
    link.setAttribute("download", "unlocked_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Unlock PDF</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder="Enter PDF Password"
      />
      <Button onClick={handleUnlockPDF} disabled={!pdfFile || isLoading}>
        Unlock PDF
      </Button>
      {unlockedPdfBlob && (
        <div>
          <Document file={unlockedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadUnlockedPdf}>Download Unlocked PDF</Button>
        </div>
      )}
    </div>
  );
};

export default UnlockPDF;
