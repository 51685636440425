import "./JwtViewerVerifier.css";
import React, { useState } from "react";
import jwt from "jsonwebtoken";
import Select from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import serviceConstants from "../../../constants/serviceConstants";
import Textarea from "../../../components/Textarea/Textarea";

const JwtViewerVerifier = () => {
  const [jwtToken, setJwtToken] = useState("");
  const [decoded, setDecoded] = useState("");
  const [secret, setSecret] = useState("");
  const [algorithm, setAlgorithm] = useState("HS256");
  const formatOptions = serviceConstants.Algorithms;

  const handleBack = () => {
    setJwtToken("");
    setDecoded("");
    setSecret("");
    setAlgorithm("HS256");
  };

  const handleJwtChange = (e) => setJwtToken(e.target.value);
  const handleSecretChange = (e) => setSecret(e.target.value);

  const decodeToken = () => {
    try {
      const decodedToken = jwt.decode(jwtToken);
      setDecoded(JSON.stringify(decodedToken, null, 2));
    } catch (error) {
      setDecoded("Invalid JWT Token");
    }
  };

  const verifyToken = () => {
    try {
      jwt.verify(jwtToken, secret, { algorithms: [algorithm] });
      setDecoded("Token is valid");
    } catch (error) {
      setDecoded("Token is invalid");
    }
  };

  return (
    <div className="bodybeautifier">
      <BackButton
        onBack={jwtToken || decoded || secret ? handleBack : null}
        title="JWT Viewer/Verifier"
      />

      <div className="column">
        <Textarea
          className="iptextarea"
          value={jwtToken}
          onChange={handleJwtChange}
          placeholder="Enter JWT here..."
        />
        <Input
          className="isp"
          type="text"
          value={secret}
          onChange={handleSecretChange}
          placeholder="Enter secret key for verification"
        />
      </div>
      <div className="column">
        <Select
          options={formatOptions}
          value={algorithm}
          onChange={(e) => setAlgorithm(e.target.value)}
          placeholder={"Algorithm"}
        />
        <Button onClick={decodeToken}>Decode JWT</Button>
        <Button onClick={verifyToken}>Verify JWT</Button>
      </div>
      {decoded && <Textarea className="Textarea" value={decoded} readOnly />}
    </div>
  );
};

export default JwtViewerVerifier;
