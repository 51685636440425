import React, { useState } from "react";
import axios from "axios";
import "./BulkCompressConvertImage.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import Image from "../../../components/Image/Image";
import BackButton from "../../../components/BackButton/BackButton";
import serviceConstants from "../../../constants/serviceConstants";
import "../image.css";

const BulkCompressConvertImage = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [outputFormat, setOutputFormat] = useState("jpeg");
  const [quality, setQuality] = useState(80);
  const [convertedImages, setConvertedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleBack = () => {
    setImageFiles([]);
    setConvertedImages([]);
    setIsLoading(false);
    setQuality(80);
    setOutputFormat("jpeg");
  };

  const handleFormatChange = (e) => {
    setOutputFormat(e.target.value);
  };

  const handleQualityChange = (e) => {
    setQuality(e.target.value);
  };

  const handleCompressConvertImages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append("imageFiles", file);
    });
    formData.append("format", outputFormat);
    formData.append("quality", quality);

    try {
      const response = await axios.post(
        `${baseUrl}/image/bulk-compress-convert`,
        formData,
        { responseType: "blob" },
      );
      setConvertedImages([
        ...convertedImages,
        URL.createObjectURL(response.data),
      ]);
    } catch (error) {
      console.error("Error compressing and converting images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-utility-container">
      <BackButton
        onBack={imageFiles.length ? handleBack : null}
        title="Bulk Compress and Convert Image Format"
      />

      {!imageFiles.length ? (
        <Input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
      ) : (
        <div>
          {imageFiles.length} {imageFiles.length === 1 ? "file" : "files"}{" "}
          selected
        </div>
      )}

      {imageFiles.length > 0 && (
        <>
          <Select
            options={formatOptions}
            value={outputFormat}
            onChange={handleFormatChange}
            placeholder={"Image Format"}
          />
          <label htmlFor="quality">Quality (1-100):</label>
          <Input
            type="range"
            id="quality"
            name="quality"
            min="1"
            max="100"
            value={quality}
            onChange={handleQualityChange}
          />
          <Button
            onClick={handleCompressConvertImages}
            disabled={imageFiles.length === 0 || isLoading}
          >
            Compress and Convert
          </Button>
        </>
      )}
      {convertedImages.length > 0 && (
        <div>
          <h3>Converted Images:</h3>
          {convertedImages.map((image, index) => (
            <Image key={index} src={image} alt={`Converted ${index}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BulkCompressConvertImage;
