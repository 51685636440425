import React, { useState } from "react";
import { Parser } from "json2csv";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

function JSONToCsvConverter() {
  const [csvData, setCsvData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [jsonInput, setJsonInput] = useState("");

  const handleBack = () => {
    setCsvData("");
    setSelectedFile(null);
    setJsonInput("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result);
        convertJsonToCsv(json);
      };
      reader.readAsText(file);
    }
  };

  const handleJsonInput = (event) => {
    const value = event.target.value;
    setJsonInput(value);
    try {
      const json = JSON.parse(value);
      convertJsonToCsv(json);
    } catch (error) {
      console.error("Invalid JSON input");
    }
  };

  const convertJsonToCsv = (json) => {
    try {
      const parser = new Parser();
      const csv = parser.parse(json);
      setCsvData(csv);
    } catch (error) {
      console.error("Error converting JSON to CSV");
    }
  };

  const downloadCsvFile = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "converted.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={selectedFile || jsonInput || csvData ? handleBack : null}
        title="JSON to CSV Converter"
      />

      {!selectedFile ? (
        <Input type="file" onChange={handleFileChange} accept=".json" />
      ) : (
        <div>{selectedFile.name}</div>
      )}

      <Textarea
        value={jsonInput}
        onChange={handleJsonInput}
        placeholder="Or paste JSON here"
      />

      {csvData && (
        <>
          <Button onClick={downloadCsvFile}>Download CSV</Button>
          <pre>{csvData}</pre>
        </>
      )}
    </div>
  );
}

export default JSONToCsvConverter;
