import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

const JsonToJsObject = () => {
  const [inputJson, setInputJson] = useState("");
  const [jsObject, setJsObject] = useState("");

  const handleBack = () => {
    setInputJson("");
    setJsObject("");
  };

  const handleInputChange = (e) => {
    setInputJson(e.target.value);
  };

  const convertToJson = () => {
    try {
      // Parse the JSON input
      const parsedJson = JSON.parse(inputJson);
      // Convert it to a string representation of a JS object
      const jsObjectString = JSON.stringify(parsedJson, null, 2);
      setJsObject(jsObjectString);
    } catch (error) {
      alert("Invalid JSON input");
      setJsObject("");
    }
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={inputJson || jsObject ? handleBack : null}
        title="Convert JSON to JavaScript Object"
      />

      <Textarea
        value={inputJson}
        onChange={handleInputChange}
        rows="6"
        cols="50"
        placeholder="Enter JSON here"
      />
      <Button onClick={convertToJson}>Convert</Button>
      {jsObject && (
        <div>
          <h3>JavaScript Object:</h3>
          <pre>{jsObject}</pre>
        </div>
      )}
    </div>
  );
};

export default JsonToJsObject;
