import React from "react";
import "./GradientSamples.css";
import serviceConstants from "../../../constants/serviceConstants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../utility.css";

/**
 * Shuffles an array using the Fisher-Yates (Knuth) shuffle algorithm.
 * This method iterates over the array from the end to the beginning,
 * swapping each element with a random element from the unprocessed segment.
 *
 * @param {Array} array - The array to be shuffled.
 * @return {Array} The shuffled array.
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index lower than the current element
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const GradientSamples = () => {
  const gradients = shuffleArray(serviceConstants.Gradients);
  const width = document.documentElement.scrollWidth;
  console.log("Document width:", (width - 200) / 5);

  const cardWidth = (width - 200) / 5;
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy!");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        overflowY: "auto",
        height: "75vh",
      }}
      className="utility-container"
    >
      <ToastContainer />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {gradients.map((gradient) => (
          <div
            key={gradient.id}
            style={{
              background: gradient.css,
              height: "200px",
              marginTop: "10px",
              marginBottom: "10px ",
              cursor: "pointer",
              width: cardWidth ? cardWidth : "250px",
              margin: "10px",
              borderRadius: "20px",
            }}
            onClick={() => copyToClipboard(gradient.css)}
          />
        ))}
      </div>
    </div>
  );
};

export default GradientSamples;
