import "./TimezoneConverter.css";
// EpochTimestampGenerator.js
import React, { useState } from "react";
import WorldMap from "react-world-map";
import moment from "moment-timezone";
import "../utility.css";

const TimezoneConverter = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [timezone, setTimezone] = useState("UTC");
  const [localTime, setLocalTime] = useState(
    moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
  );
  const [epochTime, setEpochTime] = useState(moment().tz(timezone).unix());

  const handleTimezoneChange = (event) => {
    const selectedTimezone = event.target.value;
    setTimezone(selectedTimezone);
    const nowInTimezone = moment().tz(selectedTimezone);
    setLocalTime(nowInTimezone.format("YYYY-MM-DD HH:mm:ss"));
    setEpochTime(nowInTimezone.unix());
  };

  return (
    <div className="utility-container">
      <WorldMap
        selected={selectedRegion}
        onClick={(region) => setSelectedRegion(region)}
      />
      <select value={timezone} onChange={handleTimezoneChange}>
        {moment.tz.names().map((tz) => (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ))}
      </select>
      <p>Local Time: {localTime}</p>
      <p>Epoch Time: {epochTime}</p>
    </div>
  );
};

export default TimezoneConverter;
