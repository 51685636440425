import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";

const HtmlPreview = () => {
  const [htmlInput, setHtmlInput] = useState("");
  const [sanitizedHtml, setSanitizedHtml] = useState("");
  const iframeRef = useRef(null);

  useEffect(() => {
    // Configure DOMPurify
    DOMPurify.setConfig({
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "a",
        "ul",
        "ol",
        "li",
        "br",
        "strong",
        "em",
        "img",
      ],
      ALLOWED_ATTR: ["href", "target", "src", "alt", "class"],
      ALLOW_DATA_ATTR: false,
      ADD_ATTR: ["target"],
      SANITIZE_DOM: true,
      KEEP_CONTENT: true,
      RETURN_DOM: false,
      RETURN_DOM_FRAGMENT: false,
      RETURN_DOM_IMPORT: false,
      WHOLE_DOCUMENT: false,
      FORCE_BODY: true,
    });

    // Add a hook to set all links to open in a new tab
    DOMPurify.addHook("afterSanitizeAttributes", (node) => {
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener noreferrer");
      }
    });
  }, []);

  const handleInputChange = (e) => {
    setHtmlInput(e.target.value);
  };

  const previewHtml = () => {
    const clean = DOMPurify.sanitize(htmlInput);
    setSanitizedHtml(clean);

    // Update iframe content
    if (iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <style>
              body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
              img { max-width: 100%; height: auto; }
            </style>
          </head>
          <body>${clean}</body>
        </html>
      `);
      iframeDoc.close();
    }
  };

  return (
    <div>
      <h2>HTML Preview</h2>
      <Textarea
        value={htmlInput}
        onChange={handleInputChange}
        placeholder="Enter HTML code here"
        rows={10}
      />
      <Button onClick={previewHtml}>Preview HTML</Button>
      <div>
        <h3>Preview:</h3>
        <iframe
          ref={iframeRef}
          title="HTML Preview"
          sandbox="allow-scripts"
          style={{ width: "100%", height: "400px", border: "1px solid #ccc" }}
        ></iframe>
      </div>
      <div>
        <h3>Sanitized HTML:</h3>
        <pre>{sanitizedHtml}</pre>
      </div>
    </div>
  );
};

export default HtmlPreview;
