// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcodktjbZpd4v4ksb2qHxnfSxiIMTsOik",
  authDomain: "devessentials-hive.firebaseapp.com",
  databaseURL: "https://devessentials-hive-default-rtdb.firebaseio.com",
  projectId: "devessentials-hive",
  storageBucket: "devessentials-hive.appspot.com",
  messagingSenderId: "864199243812",
  appId: "1:864199243812:web:44e36af3b3359dea4000b1",
  measurementId: "G-EJ1ZMCKLPH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
const perf = getPerformance(app);

export { database, analytics, perf };
