import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

const UrlParser = () => {
  const [url, setUrl] = useState("");
  const [parsedUrl, setParsedUrl] = useState(null);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const parseUrl = () => {
    try {
      const parsed = new URL(url);
      setParsedUrl({
        protocol: parsed.protocol,
        hostname: parsed.hostname,
        port: parsed.port,
        pathname: parsed.pathname,
        search: parsed.search,
        hash: parsed.hash,
      });
    } catch (error) {
      setParsedUrl(null);
      alert("Invalid URL");
    }
  };

  return (
    <div>
      <h2>URL Parser</h2>
      <Input
        type="text"
        value={url}
        onChange={handleUrlChange}
        placeholder="Enter URL to parse"
      />
      <Button onClick={parseUrl}>Parse URL</Button>
      {parsedUrl && (
        <div>
          <h3>Parsed URL Components:</h3>
          <p>Protocol: {parsedUrl.protocol}</p>
          <p>Hostname: {parsedUrl.hostname}</p>
          <p>Port: {parsedUrl.port || "Default"}</p>
          <p>Path: {parsedUrl.pathname}</p>
          <p>Query: {parsedUrl.search}</p>
          <p>Fragment: {parsedUrl.hash}</p>
        </div>
      )}
    </div>
  );
};

export default UrlParser;
