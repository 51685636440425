import React from "react";
import PropTypes from "prop-types";
import "./BackButton.css";
import { IoArrowBack } from "react-icons/io5";

const BackButton = ({ onBack, title }) => {
  return (
    <div className="title-container">
      {onBack && (
        <div onClick={onBack} className="back-button">
          <IoArrowBack />
        </div>
      )}
      <h2>{title}</h2>
    </div>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default BackButton;
