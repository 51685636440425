import React from "react";
import { analytics } from "./firebase"; // Adjust import path as necessary
import { logEvent } from "firebase/analytics";
import PropTypes from "prop-types";
/**
 * Description
 * @author Raghav Aadithya
 * @date 2024-01-14
 * @class ErrorBoundary
 * @extends {React.Component}
 */
class ErrorBoundary extends React.Component {
  /**
   * Description
   * @author Raghav Aadithya
   * @date 2024-01-14
   * @param {any} props

   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Description
   * @author Raghav Aadithya
   * @date 2024-01-14
   * @static
   * @param {any} error
   * @return {object}
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * Description
   * @author Raghav Aadithya
   * @date 2024-01-14
   * @param {any} error
   * @param {any} errorInfo
   */
  componentDidCatch(error) {
    logEvent(analytics, "exception", { description: `${error.message}` });
  }

  /**
   * Description
   * @author Raghav Aadithya
   * @date 2024-01-14
   * @return {any}
   */
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired, // Require a node as children (text, JSX, etc.)
};
export default ErrorBoundary;
