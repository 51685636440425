module.exports = {
  ImageFormatOptions: [
    { value: "png", label: "PNG" },
    { value: "jpeg", label: "JPEG" },
    { value: "webp", label: "WEBP" },
    { value: "gif", label: "GIF" },
    { value: "jp2", label: "JP2" },
    { value: "tiff", label: "TIFF" },
    { value: "avif", label: "AVIF" },
    { value: "heif", label: "HEIF" },
    { value: "jxl", label: "JXL" },
    { value: "raw", label: "RAW" },
  ],
  ImageSizeOptions: [
    { value: "KB", label: "KB" },
    { value: "MB", label: "MB" },
  ],
  Algorithms: [
    { value: "HS256", label: "HS256" },
    { value: "HS384", label: "HS384" },
    { value: "HS512", label: "HS512" },
    { value: "RS256", label: "RS256" },
    { value: "RS384", label: "RS384" },
    { value: "RS512", label: "RS512" },
    { value: "ES256", label: "ES256" },
    { value: "ES384", label: "ES384" },
    { value: "ES512", label: "ES512" },
    { value: "PS256", label: "PS256" },
    { value: "PS384", label: "PS384" },
    { value: "PS512", label: "PS512" },
    { value: "none", label: "none" },
  ],
  Gradients: [
    {
      id: 1,
      name: "Red-Yellow Gradient",
      css: "linear-gradient(to right, red, yellow)",
      type: "warm",
    },
    {
      id: 2,
      name: "Blue-Green Gradient",
      css: "linear-gradient(to right, blue, green)",
      type: "cool",
    },
    {
      id: 3,
      name: "Purple-Pink Gradient",
      css: "linear-gradient(to right, purple, pink)",
      type: "cool",
    },
    {
      id: 4,
      name: "Orange-Yellow Gradient",
      css: "linear-gradient(to right, orange, yellow)",
      type: "warm",
    },
    {
      id: 5,
      name: "Teal-Lime Gradient",
      css: "linear-gradient(to right, teal, lime)",
      type: "cool",
    },
    {
      id: 6,
      name: "Magenta-Cyan Gradient",
      css: "linear-gradient(to right, magenta, cyan)",
      type: "cool",
    },
    {
      id: 7,
      name: "Violet-Indigo Gradient",
      css: "linear-gradient(to right, violet, indigo)",
      type: "cool",
    },
    {
      id: 8,
      name: "Gold-Silver Gradient",
      css: "linear-gradient(to right, gold, silver)",
      type: "warm",
    },
    {
      id: 9,
      name: "Maroon-Beige Gradient",
      css: "linear-gradient(to right, maroon, beige)",
      type: "warm",
    },
    {
      id: 10,
      name: "Turquoise-Gray Gradient",
      css: "linear-gradient(to right, turquoise, gray)",
      type: "cool",
    },
    {
      id: 11,
      name: "Crimson-Lavender Gradient",
      css: "linear-gradient(to right, crimson, lavender)",
      type: "warm",
    },
    {
      id: 13,
      name: "Olive-Cyan Gradient",
      css: "linear-gradient(to right, olive, cyan)",
      type: "warm",
    },
    {
      id: 17,
      name: "Indigo-Azure Gradient",
      css: "linear-gradient(to right, indigo, azure)",
      type: "cool",
    },
    {
      id: 19,
      name: "Crimson-Gold Gradient",
      css: "linear-gradient(to right, crimson, gold)",
      type: "warm",
    },
    {
      id: 20,
      name: "Pink-Lavender Gradient",
      css: "linear-gradient(to right, pink, lavender)",
      type: "warm",
    },
    {
      id: 21,
      name: "Lavender-Indigo Gradient",
      css: "linear-gradient(to right, lavender, indigo)",
      type: "cool",
    },
    {
      id: 22,
      name: "Yellow-Orange Gradient",
      css: "linear-gradient(to right, yellow, orange)",
      type: "warm",
    },
    {
      id: 23,
      name: "Turquoise-Cyan Gradient",
      css: "linear-gradient(to right, turquoise, cyan)",
      type: "cool",
    },
    {
      id: 24,
      name: "Magenta-Purple Gradient",
      css: "linear-gradient(to right, magenta, purple)",
      type: "cool",
    },
    {
      id: 25,
      name: "Green-Lime Gradient",
      css: "linear-gradient(to right, green, lime)",
      type: "warm",
    },
    {
      id: 28,
      name: "Blue-Indigo Gradient",
      css: "linear-gradient(to right, blue, indigo)",
      type: "cool",
    },
    {
      id: 31,
      name: "Magenta-Yellow Gradient",
      css: "linear-gradient(to right, magenta, yellow)",
      type: "warm",
    },
    {
      id: 33,
      name: "Green-Olive Gradient",
      css: "linear-gradient(to right, green, olive)",
      type: "warm",
    },
    {
      id: 34,
      name: "Purple-Violet Gradient",
      css: "linear-gradient(to right, purple, violet)",
      type: "cool",
    },
    {
      id: 35,
      name: "Cyan-Teal Gradient",
      css: "linear-gradient(to right, cyan, teal)",
      type: "cool",
    },
    {
      id: 36,
      name: "Gold-Yellow Gradient",
      css: "linear-gradient(to right, gold, yellow)",
      type: "warm",
    },
    {
      id: 38,
      name: "Turquoise-Lime Gradient",
      css: "linear-gradient(to right, turquoise, lime)",
      type: "cool",
    },
    {
      id: 39,
      name: "Magenta-Red Gradient",
      css: "linear-gradient(to right, magenta, red)",
      type: "warm",
    },
    {
      id: 40,
      name: "Pink-Orange Gradient",
      css: "linear-gradient(to right, pink, orange)",
      type: "warm",
    },
    {
      id: 41,
      name: "Cyan-Lavender Gradient",
      css: "linear-gradient(to right, cyan, lavender)",
      type: "cool",
    },
    {
      id: 44,
      name: "Gold-Purple Gradient",
      css: "linear-gradient(to right, gold, purple)",
      type: "warm",
    },
    {
      id: 45,
      name: "Maroon-Lime Gradient",
      css: "linear-gradient(to right, maroon, lime)",
      type: "warm",
    },
    {
      id: 46,
      name: "Orange-Teal Gradient",
      css: "linear-gradient(to right, orange, teal)",
      type: "warm",
    },
    {
      id: 47,
      name: "Pink-Indigo Gradient",
      css: "linear-gradient(to right, pink, indigo)",
      type: "cool",
    },
    {
      id: 48,
      name: "Red-Turquoise Gradient",
      css: "linear-gradient(to right, red, turquoise)",
      type: "cool",
    },
    {
      id: 49,
      name: "Teal-Beige Gradient",
      css: "linear-gradient(to right, teal, beige)",
      type: "warm",
    },
    {
      id: 50,
      name: "Crimson-Gray Gradient",
      css: "linear-gradient(to right, crimson, gray)",
      type: "cool",
    },
    {
      id: 51,
      name: "Lavender-Orange Gradient",
      css: "linear-gradient(to right, lavender, orange)",
      type: "warm",
    },
    {
      id: 54,
      name: "Turquoise-Red Gradient",
      css: "linear-gradient(to right, turquoise, red)",
      type: "warm",
    },
    {
      id: 55,
      name: "Purple-Pink Gradient",
      css: "linear-gradient(to right, purple, pink)",
      type: "cool",
    },
    {
      id: 56,
      name: "Teal-Yellow Gradient",
      css: "linear-gradient(to right, teal, yellow)",
      type: "warm",
    },
    {
      id: 57,
      name: "Coral-Lime Gradient",
      css: "linear-gradient(to right, coral, lime)",
      type: "warm",
    },
    {
      id: 58,
      name: "Crimson-Turquoise Gradient",
      css: "linear-gradient(to right, crimson, turquoise)",
      type: "cool",
    },
    {
      id: 59,
      name: "Magenta-Beige Gradient",
      css: "linear-gradient(to right, magenta, beige)",
      type: "warm",
    },
    {
      id: 60,
      name: "Gold-Pink Gradient",
      css: "linear-gradient(to right, gold, pink)",
      type: "warm",
    },
    {
      id: 61,
      name: "Maroon-Lavender Gradient",
      css: "linear-gradient(to right, maroon, lavender)",
      type: "cool",
    },
    {
      id: 62,
      name: "Blue-Orange Gradient",
      css: "linear-gradient(to right, blue, orange)",
      type: "warm",
    },
    {
      id: 63,
      name: "Green-Crimson Gradient",
      css: "linear-gradient(to right, green, crimson)",
      type: "cool",
    },
    {
      id: 64,
      name: "Cyan-Magenta Gradient",
      css: "linear-gradient(to right, cyan, magenta)",
      type: "cool",
    },
    {
      id: 65,
      name: "Teal-Gray Gradient",
      css: "linear-gradient(to right, teal, gray)",
      type: "cool",
    },
    {
      id: 68,
      name: "Gold-Purple Gradient",
      css: "linear-gradient(to right, gold, purple)",
      type: "warm",
    },
    {
      id: 69,
      name: "Maroon-Lime Gradient",
      css: "linear-gradient(to right, maroon, lime)",
      type: "warm",
    },
    {
      id: 70,
      name: "Orange-Teal Gradient",
      css: "linear-gradient(to right, orange, teal)",
      type: "warm",
    },
    {
      id: 71,
      name: "Pink-Indigo Gradient",
      css: "linear-gradient(to right, pink, indigo)",
      type: "cool",
    },
    {
      id: 72,
      name: "Red-Turquoise Gradient",
      css: "linear-gradient(to right, red, turquoise)",
      type: "cool",
    },
    {
      id: 73,
      name: "Teal-Beige Gradient",
      css: "linear-gradient(to right, teal, beige)",
      type: "warm",
    },
    {
      id: 74,
      name: "Crimson-Gray Gradient",
      css: "linear-gradient(to right, crimson, gray)",
      type: "cool",
    },
    {
      id: 75,
      name: "Lavender-Orange Gradient",
      css: "linear-gradient(to right, lavender, orange)",
      type: "warm",
    },
    {
      id: 78,
      name: "Turquoise-Red Gradient",
      css: "linear-gradient(to right, turquoise, red)",
      type: "warm",
    },
    {
      id: 79,
      name: "Purple-Pink Gradient",
      css: "linear-gradient(to right, purple, pink)",
      type: "cool",
    },
    {
      id: 80,
      name: "Teal-Yellow Gradient",
      css: "linear-gradient(to right, teal, yellow)",
      type: "warm",
    },
    {
      id: 81,
      name: "Sunset Gradient",
      css: "linear-gradient(to right, #FF6A00, #ED213A)",
      type: "warm",
    },
    {
      id: 82,
      name: "Ocean Blue Gradient",
      css: "linear-gradient(to right, #1488CC, #2B32B2)",
      type: "cool",
    },
    {
      id: 83,
      name: "Amethyst Gradient",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 84,
      name: "Autumn Leaves Gradient",
      css: "linear-gradient(to right, #D558C8, #24B3A1)",
      type: "warm",
    },
    {
      id: 85,
      name: "Minty Fresh Gradient",
      css: "linear-gradient(to right, #74EBD5, #ACB6E5)",
      type: "cool",
    },
    {
      id: 86,
      name: "Fire and Ice Gradient",
      css: "linear-gradient(to right, #FF416C, #FF4B2B)",
      type: "cool",
    },
    {
      id: 87,
      name: "Blue Orchid Gradient",
      css: "linear-gradient(to right, #2657EB, #DE2A92)",
      type: "cool",
    },
    {
      id: 88,
      name: "Golden Hour Gradient",
      css: "linear-gradient(to right, #FFD700, #F7971E)",
      type: "warm",
    },
    {
      id: 89,
      name: "Aubergine Sunrise Gradient",
      css: "linear-gradient(to right, #AA076B, #61045F)",
      type: "warm",
    },
    {
      id: 90,
      name: "Emerald Bay Gradient",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 91,
      name: "Passion Fruit Gradient",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 92,
      name: "Mango Tango Gradient",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 93,
      name: "Azure Skies Gradient",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 94,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 95,
      name: "Lemon Zest Gradient",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 96,
      name: "Royal Plum Gradient",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 97,
      name: "Forest Canopy Gradient",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 98,
      name: "Cherry Blossom Gradient",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 99,
      name: "Citrus Splash Gradient",
      css: "linear-gradient(to right, #FF8008, #FFC837)",
      type: "warm",
    },
    {
      id: 100,
      name: "Emerald Lakes Gradient",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 101,
      name: "Tropical Sunset Gradient",
      css: "linear-gradient(to right, #FF512F, #DD2476)",
      type: "warm",
    },
    {
      id: 102,
      name: "Cool Breeze Gradient",
      css: "linear-gradient(to right, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 103,
      name: "Raspberry Sorbet Gradient",
      css: "linear-gradient(to right, #FF0099, #493240)",
      type: "cool",
    },
    {
      id: 104,
      name: "Lime Punch Gradient",
      css: "linear-gradient(to right, #D9BF77, #ACD8AA)",
      type: "warm",
    },
    {
      id: 105,
      name: "Turquoise Sea Gradient",
      css: "linear-gradient(to right, #61A3FE, #F7B5C0)",
      type: "cool",
    },
    {
      id: 106,
      name: "Mystic Forest Gradient",
      css: "linear-gradient(to right, #517fa4, #243949)",
      type: "cool",
    },
    {
      id: 107,
      name: "Pink Lemonade Gradient",
      css: "linear-gradient(to right, #FFD3A5, #FD6585)",
      type: "warm",
    },
    {
      id: 108,
      name: "Sandy Beach Gradient",
      css: "linear-gradient(to right, #FFD89B, #19547B)",
      type: "cool",
    },
    {
      id: 109,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 110,
      name: "Cotton Candy Gradient",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 111,
      name: "Ruby Red Gradient",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 112,
      name: "Emerald Bay Gradient",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 113,
      name: "Passion Fruit Gradient",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 114,
      name: "Mango Tango Gradient",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 115,
      name: "Azure Skies Gradient",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 116,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 117,
      name: "Lemon Zest Gradient",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 118,
      name: "Royal Plum Gradient",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 119,
      name: "Forest Canopy Gradient",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 120,
      name: "Cherry Blossom Gradient",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 121,
      name: "Blue Sky Gradient",
      css: "linear-gradient(to right, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 122,
      name: "Crimson Sunset Gradient",
      css: "linear-gradient(to right, #FF512F, #DD2476)",
      type: "warm",
    },
    {
      id: 123,
      name: "Purple Haze Gradient",
      css: "linear-gradient(to right, #D9BF77, #ACD8AA)",
      type: "warm",
    },
    {
      id: 124,
      name: "Turquoise Delight Gradient",
      css: "linear-gradient(to right, #61A3FE, #F7B5C0)",
      type: "cool",
    },
    {
      id: 125,
      name: "Mystic Forest Gradient",
      css: "linear-gradient(to right, #517fa4, #243949)",
      type: "cool",
    },
    {
      id: 126,
      name: "Pink Lemonade Gradient",
      css: "linear-gradient(to right, #FFD3A5, #FD6585)",
      type: "warm",
    },
    {
      id: 127,
      name: "Sandy Beach Gradient",
      css: "linear-gradient(to right, #FFD89B, #19547B)",
      type: "cool",
    },
    {
      id: 128,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 129,
      name: "Cotton Candy Gradient",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 130,
      name: "Ruby Red Gradient",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 131,
      name: "Emerald Bay Gradient",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 132,
      name: "Passion Fruit Gradient",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 133,
      name: "Mango Tango Gradient",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 134,
      name: "Azure Skies Gradient",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 135,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 136,
      name: "Lemon Zest Gradient",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 137,
      name: "Royal Plum Gradient",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 138,
      name: "Forest Canopy Gradient",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 139,
      name: "Cherry Blossom Gradient",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 140,
      name: "Blue Sky Gradient",
      css: "linear-gradient(to right, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 141,
      name: "Crimson Sunset Gradient",
      css: "linear-gradient(to right, #FF512F, #DD2476)",
      type: "warm",
    },
    {
      id: 142,
      name: "Purple Haze Gradient",
      css: "linear-gradient(to right, #D9BF77, #ACD8AA)",
      type: "warm",
    },
    {
      id: 143,
      name: "Turquoise Delight Gradient",
      css: "linear-gradient(to right, #61A3FE, #F7B5C0)",
      type: "cool",
    },
    {
      id: 144,
      name: "Mystic Forest Gradient",
      css: "linear-gradient(to right, #517fa4, #243949)",
      type: "cool",
    },
    {
      id: 145,
      name: "Pink Lemonade Gradient",
      css: "linear-gradient(to right, #FFD3A5, #FD6585)",
      type: "warm",
    },
    {
      id: 146,
      name: "Sandy Beach Gradient",
      css: "linear-gradient(to right, #FFD89B, #19547B)",
      type: "cool",
    },
    {
      id: 147,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 148,
      name: "Cotton Candy Gradient",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 149,
      name: "Ruby Red Gradient",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 150,
      name: "Emerald Bay Gradient",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 151,
      name: "Passion Fruit Gradient",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 152,
      name: "Mango Tango Gradient",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 153,
      name: "Azure Skies Gradient",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 154,
      name: "Lavender Fields Gradient",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 155,
      name: "Lemon Zest Gradient",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 156,
      name: "Royal Plum Gradient",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 157,
      name: "Forest Canopy Gradient",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 158,
      name: "Cherry Blossom Gradient",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 159,
      name: "Blue Sky Gradient",
      css: "linear-gradient(to right, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 160,
      name: "Crimson Sunset Gradient",
      css: "linear-gradient(to right, #FF512F, #DD2476)",
      type: "warm",
    },
    {
      id: 161,
      name: "Ocean Sunrise",
      css: "linear-gradient(to right, #FF5733, #6A0572)",
      type: "cool",
    },
    {
      id: 162,
      name: "Minty Breeze",
      css: "linear-gradient(to right, #04BFBF, #CAFCD8)",
      type: "cool",
    },
    {
      id: 163,
      name: "Aqua Waves",
      css: "linear-gradient(to right, #B2FFD6, #2D6F7F)",
      type: "cool",
    },
    {
      id: 164,
      name: "Raspberry Sorbet",
      css: "linear-gradient(to right, #F6787D, #FAA2C1)",
      type: "warm",
    },
    {
      id: 165,
      name: "Elegant Evening",
      css: "linear-gradient(to right, #7B4397, #DC2430)",
      type: "cool",
    },
    {
      id: 166,
      name: "Spring Meadow",
      css: "linear-gradient(to right, #96E6A1, #D4FC79)",
      type: "cool",
    },
    {
      id: 167,
      name: "Amethyst Skies",
      css: "linear-gradient(to right, #5F2C82, #49A09D)",
      type: "cool",
    },
    {
      id: 168,
      name: "Golden Fields",
      css: "linear-gradient(to right, #DDBD4D, #CE8D3E)",
      type: "warm",
    },
    {
      id: 169,
      name: "Passionate Pink",
      css: "linear-gradient(to right, #FF0084, #33001B)",
      type: "cool",
    },
    {
      id: 170,
      name: "Turquoise Delight",
      css: "linear-gradient(to right, #0093E9, #80D0C7)",
      type: "cool",
    },
    {
      id: 171,
      name: "Blue Lagoon",
      css: "linear-gradient(to right, #00F260, #0575E6)",
      type: "cool",
    },
    {
      id: 172,
      name: "Crimson Skies",
      css: "linear-gradient(to right, #D11965, #FFCE00)",
      type: "warm",
    },
    {
      id: 173,
      name: "Sunset Horizon",
      css: "linear-gradient(to right, #FF6A00, #ED213A)",
      type: "warm",
    },
    {
      id: 174,
      name: "Emerald Enchantment",
      css: "linear-gradient(to right, #00CDAC, #8EE9B4)",
      type: "cool",
    },
    {
      id: 175,
      name: "Purple Rain",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 176,
      name: "Golden Sunset",
      css: "linear-gradient(to right, #FFD700, #F7971E)",
      type: "warm",
    },
    {
      id: 177,
      name: "Lavender Mist",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 178,
      name: "Lemonade Crush",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 179,
      name: "Royal Velvet",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 180,
      name: "Forest Canopy",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 181,
      name: "Cherry Blossom",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 182,
      name: "Aurora Borealis",
      css: "linear-gradient(to right, #00FFFF, #0033FF)",
      type: "cool",
    },
    {
      id: 183,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 184,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 185,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 186,
      name: "Cotton Candy",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 187,
      name: "Ruby Red",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 188,
      name: "Emerald Bay",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 189,
      name: "Passion Fruit",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 190,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 191,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 192,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 193,
      name: "Lemon Zest",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 194,
      name: "Royal Plum",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 195,
      name: "Forest Canopy",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 196,
      name: "Cherry Blossom",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 197,
      name: "Aurora Borealis",
      css: "linear-gradient(to right, #00FFFF, #0033FF)",
      type: "cool",
    },
    {
      id: 198,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 199,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 200,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 201,
      name: "Blue Oasis",
      css: "linear-gradient(to right, #0575E6, #00F260)",
      type: "cool",
    },
    {
      id: 202,
      name: "Coral Reef",
      css: "linear-gradient(to right, #FF6A00, #ED213A)",
      type: "warm",
    },
    {
      id: 203,
      name: "Emerald Gem",
      css: "radial-gradient(circle, #00FFC3, #009EDE)",
      type: "cool",
    },
    {
      id: 204,
      name: "Golden Glow",
      css: "radial-gradient(circle, #FFC837, #FF8008)",
      type: "warm",
    },
    {
      id: 205,
      name: "Amethyst Dream",
      css: "linear-gradient(to right, #5F2C82, #49A09D)",
      type: "cool",
    },
    {
      id: 206,
      name: "Lavender Bliss",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 207,
      name: "Citrus Splash",
      css: "linear-gradient(to right, #FF8008, #FFC837)",
      type: "warm",
    },
    {
      id: 208,
      name: "Sandy Shores",
      css: "linear-gradient(to right, #FFD89B, #19547B)",
      type: "cool",
    },
    {
      id: 209,
      name: "Autumn Leaves",
      css: "radial-gradient(circle, #D558C8, #24B3A1)",
      type: "warm",
    },
    {
      id: 210,
      name: "Ocean Breeze",
      css: "radial-gradient(circle, #1488CC, #2B32B2)",
      type: "cool",
    },
    {
      id: 211,
      name: "Fire and Ice",
      css: "linear-gradient(to right, #FF416C, #FF4B2B)",
      type: "cool",
    },
    {
      id: 212,
      name: "Minty Fresh",
      css: "linear-gradient(to right, #74EBD5, #ACB6E5)",
      type: "cool",
    },
    {
      id: 213,
      name: "Cherry Blossom",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 214,
      name: "Crimson Sunset",
      css: "linear-gradient(to right, #FF512F, #DD2476)",
      type: "warm",
    },
    {
      id: 215,
      name: "Emerald Bay",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 216,
      name: "Passion Fruit",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 217,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 218,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 219,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 220,
      name: "Cotton Candy",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 221,
      name: "Ruby Red",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 222,
      name: "Emerald Bay",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 223,
      name: "Passion Fruit",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 224,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 225,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 226,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 227,
      name: "Lemon Zest",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 228,
      name: "Royal Plum",
      css: "linear-gradient(to right, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 229,
      name: "Forest Canopy",
      css: "linear-gradient(to right, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 230,
      name: "Cherry Blossom",
      css: "linear-gradient(to right, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 231,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 232,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 233,
      name: "Cotton Candy",
      css: "linear-gradient(to right, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 234,
      name: "Ruby Red",
      css: "linear-gradient(to right, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 235,
      name: "Emerald Bay",
      css: "linear-gradient(to right, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 236,
      name: "Passion Fruit",
      css: "linear-gradient(to right, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 237,
      name: "Mango Tango",
      css: "linear-gradient(to right, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 238,
      name: "Azure Skies",
      css: "linear-gradient(to right, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 239,
      name: "Lavender Fields",
      css: "linear-gradient(to right, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 240,
      name: "Lemon Zest",
      css: "linear-gradient(to right, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 241,
      name: "Golden Sunrise",
      css: "radial-gradient(circle, #FFDD00, #FF6A00)",
      type: "warm",
    },
    {
      id: 242,
      name: "Turquoise Waters",
      css: "radial-gradient(circle, #00FFFF, #0033FF)",
      type: "cool",
    },
    {
      id: 243,
      name: "Silver Moonlight",
      css: "radial-gradient(circle, #D6EAF8, #A9CCE3)",
      type: "cool",
    },
    {
      id: 244,
      name: "Lilac Dreams",
      css: "radial-gradient(circle, #BB8FCE, #A5A4E3)",
      type: "cool",
    },
    {
      id: 245,
      name: "Golden Harvest",
      css: "radial-gradient(circle, #FDCB73, #FFD700)",
      type: "warm",
    },
    {
      id: 246,
      name: "Sapphire Skies",
      css: "radial-gradient(circle, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 247,
      name: "Crimson Flame",
      css: "radial-gradient(circle, #FF416C, #FF4B2B)",
      type: "warm",
    },
    {
      id: 248,
      name: "Emerald Enchantment",
      css: "radial-gradient(circle, #00CDAC, #8EE9B4)",
      type: "cool",
    },
    {
      id: 249,
      name: "Violet Twilight",
      css: "radial-gradient(circle, #7556A7, #C5C3D0)",
      type: "cool",
    },
    {
      id: 250,
      name: "Golden Sands",
      css: "radial-gradient(circle, #FFC837, #FF8008)",
      type: "warm",
    },
    {
      id: 251,
      name: "Azure Dreams",
      css: "radial-gradient(circle, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 252,
      name: "Lavender Serenity",
      css: "radial-gradient(circle, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 253,
      name: "Minty Marvel",
      css: "radial-gradient(circle, #74EBD5, #ACB6E5)",
      type: "cool",
    },
    {
      id: 254,
      name: "Ruby Delight",
      css: "radial-gradient(circle, #FF0084, #33001B)",
      type: "cool",
    },
    {
      id: 255,
      name: "Citrus Sunrise",
      css: "radial-gradient(circle, #FF8008, #FFC837)",
      type: "warm",
    },
    {
      id: 256,
      name: "Lemon Zest",
      css: "radial-gradient(circle, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 257,
      name: "Royal Velvet",
      css: "radial-gradient(circle, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 258,
      name: "Forest Canopy",
      css: "radial-gradient(circle, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 259,
      name: "Cherry Blossom",
      css: "radial-gradient(circle, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 260,
      name: "Aurora Borealis",
      css: "radial-gradient(circle, #00FFFF, #0033FF)",
      type: "cool",
    },
    {
      id: 261,
      name: "Mango Tango",
      css: "radial-gradient(circle, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 262,
      name: "Azure Skies",
      css: "radial-gradient(circle, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 263,
      name: "Lavender Fields",
      css: "radial-gradient(circle, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 264,
      name: "Cotton Candy",
      css: "radial-gradient(circle, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 265,
      name: "Ruby Red",
      css: "radial-gradient(circle, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 266,
      name: "Emerald Bay",
      css: "radial-gradient(circle, #56AB2F, #A8E063)",
      type: "cool",
    },
    {
      id: 267,
      name: "Passion Fruit",
      css: "radial-gradient(circle, #FF758C, #FF7EB3)",
      type: "warm",
    },
    {
      id: 268,
      name: "Mango Tango",
      css: "radial-gradient(circle, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 269,
      name: "Azure Skies",
      css: "radial-gradient(circle, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 270,
      name: "Lavender Fields",
      css: "radial-gradient(circle, #7F00FF, #E100FF)",
      type: "cool",
    },
    {
      id: 271,
      name: "Lemon Zest",
      css: "radial-gradient(circle, #FFEC00, #F7971E)",
      type: "warm",
    },
    {
      id: 272,
      name: "Royal Plum",
      css: "radial-gradient(circle, #7B0058, #55008B)",
      type: "cool",
    },
    {
      id: 273,
      name: "Forest Canopy",
      css: "radial-gradient(circle, #6A9113, #141517)",
      type: "cool",
    },
    {
      id: 274,
      name: "Cherry Blossom",
      css: "radial-gradient(circle, #FDCB73, #FFEBA2)",
      type: "warm",
    },
    {
      id: 275,
      name: "Aurora Borealis",
      css: "radial-gradient(circle, #00FFFF, #0033FF)",
      type: "cool",
    },
    {
      id: 276,
      name: "Mango Tango",
      css: "radial-gradient(circle, #FFD400, #F7971E)",
      type: "warm",
    },
    {
      id: 277,
      name: "Azure Skies",
      css: "radial-gradient(circle, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 278,
      name: "Lavender Fields",
      css: "radial-gradient(circle, #6A0572, #AB83A1)",
      type: "cool",
    },
    {
      id: 279,
      name: "Cotton Candy",
      css: "radial-gradient(circle, #FCA5F1, #B5FFFF)",
      type: "cool",
    },
    {
      id: 280,
      name: "Ruby Red",
      css: "radial-gradient(circle, #9D50BB, #6E48AA)",
      type: "cool",
    },
    {
      id: 321,
      name: "Sapphire Sparks",
      css: "radial-gradient(circle, #174AE1, #30B3E2)",
      type: "cool",
    },
    {
      id: 322,
      name: "Tropical Punch",
      css: "radial-gradient(circle, #FF7F00, #FFD700)",
      type: "warm",
    },
    {
      id: 323,
      name: "Neon Glow",
      css: "radial-gradient(circle, #FF00FF, #00FFFF)",
      type: "cool",
    },
    {
      id: 324,
      name: "Lime Fizz",
      css: "radial-gradient(circle, #00FF00, #FFFF00)",
      type: "warm",
    },
    {
      id: 325,
      name: "Electric Blue",
      css: "radial-gradient(circle, #00FFFF, #0000FF)",
      type: "cool",
    },
    {
      id: 326,
      name: "Sunset Hues",
      css: "radial-gradient(circle, #FF6A00, #FFD700)",
      type: "warm",
    },
    {
      id: 327,
      name: "Purple Passion",
      css: "radial-gradient(circle, #9B30FF, #FFD700)",
      type: "cool",
    },
    {
      id: 328,
      name: "Emerald Elegance",
      css: "radial-gradient(circle, #00FF00, #228B22)",
      type: "cool",
    },
    {
      id: 329,
      name: "Vibrant Violets",
      css: "radial-gradient(circle, #8A2BE2, #8B008B)",
      type: "cool",
    },
    {
      id: 330,
      name: "Radiant Ruby",
      css: "radial-gradient(circle, #FF2400, #FFD700)",
      type: "warm",
    },
    {
      id: 331,
      name: "Turquoise Delight",
      css: "radial-gradient(circle, #40E0D0, #00CED1)",
      type: "cool",
    },
    {
      id: 332,
      name: "Copper Blaze",
      css: "radial-gradient(circle, #B87333, #FFD700)",
      type: "warm",
    },
    {
      id: 333,
      name: "Mystic Magenta",
      css: "radial-gradient(circle, #FF00FF, #8A2BE2)",
      type: "cool",
    },
    {
      id: 334,
      name: "Lemon Zest",
      css: "radial-gradient(circle, #FFD700, #FFFF00)",
      type: "warm",
    },
    {
      id: 335,
      name: "Tropical Paradise",
      css: "radial-gradient(circle, #00FF00, #00FFFF)",
      type: "cool",
    },
    {
      id: 336,
      name: "Flamingo Pink",
      css: "radial-gradient(circle, #FF1493, #FF69B4)",
      type: "warm",
    },
    {
      id: 337,
      name: "Electric Sunshine",
      css: "radial-gradient(circle, #FFFF00, #FFD700)",
      type: "warm",
    },
    {
      id: 338,
      name: "Skyline Dreams",
      css: "radial-gradient(circle, #00BFFF, #1E90FF)",
      type: "cool",
    },
    {
      id: 339,
      name: "Jungle Fever",
      css: "radial-gradient(circle, #228B22, #008000)",
      type: "cool",
    },
    {
      id: 340,
      name: "Fuchsia Frenzy",
      css: "radial-gradient(circle, #FF00FF, #FF1493)",
      type: "cool",
    },
    {
      id: 341,
      name: "Crimson Carnival",
      css: "radial-gradient(circle, #DC143C, #FFD700)",
      type: "warm",
    },
    {
      id: 342,
      name: "Oceanic Wonders",
      css: "radial-gradient(circle, #00CED1, #20B2AA)",
      type: "cool",
    },
    {
      id: 343,
      name: "Golden Radiance",
      css: "radial-gradient(circle, #FFD700, #FF4500)",
      type: "warm",
    },
    {
      id: 344,
      name: "Magenta Magic",
      css: "radial-gradient(circle, #FF00FF, #8B008B)",
      type: "cool",
    },
    {
      id: 345,
      name: "Emerald Dreams",
      css: "radial-gradient(circle, #00FF00, #008000)",
      type: "cool",
    },
    {
      id: 346,
      name: "Vivid Sunset",
      css: "radial-gradient(circle, #FF4500, #FFA500)",
      type: "warm",
    },
    {
      id: 347,
      name: "Purple Paradise",
      css: "radial-gradient(circle, #800080, #9370DB)",
      type: "cool",
    },
    {
      id: 348,
      name: "Turquoise Tranquility",
      css: "radial-gradient(circle, #00CED1, #87CEEB)",
      type: "cool",
    },
    {
      id: 349,
      name: "Golden Glimmer",
      css: "radial-gradient(circle, #FFD700, #FF6347)",
      type: "warm",
    },
    {
      id: 350,
      name: "Lilac Luster",
      css: "radial-gradient(circle, #C71585, #DA70D6)",
      type: "cool",
    },
    {
      id: 351,
      name: "Tropical Breeze",
      css: "radial-gradient(circle, #00FF00, #008000)",
      type: "cool",
    },
    {
      id: 352,
      name: "Blue Lagoon",
      css: "radial-gradient(circle, #00BFFF, #00CED1)",
      type: "cool",
    },
    {
      id: 353,
      name: "Ruby Royale",
      css: "radial-gradient(circle, #E32636, #FFD700)",
      type: "warm",
    },
    {
      id: 354,
      name: "Sunrise Serenity",
      css: "radial-gradient(circle, #FF4500, #FF6347)",
      type: "warm",
    },
    {
      id: 355,
      name: "Magenta Mirage",
      css: "radial-gradient(circle, #FF00FF, #8A2BE2)",
      type: "cool",
    },
    {
      id: 356,
      name: "Emerald Oasis",
      css: "radial-gradient(circle, #00FF00, #228B22)",
      type: "cool",
    },
    {
      id: 357,
      name: "Vibrant Violets",
      css: "radial-gradient(circle, #8A2BE2, #800080)",
      type: "cool",
    },
    {
      id: 358,
      name: "Radiant Ruby",
      css: "radial-gradient(circle, #FF2400, #FFD700)",
      type: "warm",
    },
    {
      id: 359,
      name: "Turquoise Delight",
      css: "radial-gradient(circle, #40E0D0, #00CED1)",
      type: "cool",
    },
    {
      id: 360,
      name: "Copper Blaze",
      css: "radial-gradient(circle, #B87333, #FFD700)",
      type: "warm",
    },
    {
      id: 361,
      name: "Luminous Lavender",
      css: "radial-gradient(circle, #D8BFD8, #E6E6FA)",
      type: "cool",
    },
    {
      id: 362,
      name: "Funky Fandango",
      css: "radial-gradient(circle, #F06292, #FFD700)",
      type: "warm",
    },
    {
      id: 363,
      name: "Electric Euphoria",
      css: "radial-gradient(circle, #FF00FF, #FF4500)",
      type: "cool",
    },
    {
      id: 364,
      name: "Citrus Splash",
      css: "radial-gradient(circle, #FF8008, #FFC837)",
      type: "warm",
    },
    {
      id: 365,
      name: "Azure Haze",
      css: "radial-gradient(circle, #0072FF, #00C6FF)",
      type: "cool",
    },
    {
      id: 366,
      name: "Lavender Love",
      css: "radial-gradient(circle, #9370DB, #D8BFD8)",
      type: "cool",
    },
    {
      id: 367,
      name: "Golden Meadows",
      css: "radial-gradient(circle, #FFD700, #228B22)",
      type: "warm",
    },
    {
      id: 368,
      name: "Violet Veil",
      css: "radial-gradient(circle, #8B008B, #DDA0DD)",
      type: "cool",
    },
    {
      id: 369,
      name: "Emerald Enchantment",
      css: "radial-gradient(circle, #00CDAC, #8EE9B4)",
      type: "cool",
    },
    {
      id: 370,
      name: "Sapphire Skies",
      css: "radial-gradient(circle, #56CCF2, #2F80ED)",
      type: "cool",
    },
    {
      id: 371,
      name: "Crimson Flame",
      css: "radial-gradient(circle, #FF416C, #FF4B2B)",
      type: "warm",
    },
    {
      id: 372,
      name: "Minty Marvel",
      css: "radial-gradient(circle, #74EBD5, #ACB6E5)",
      type: "cool",
    },
    {
      id: 373,
      name: "Ruby Delight",
      css: "radial-gradient(circle, #FF0084, #33001B)",
      type: "cool",
    },
    {
      id: 374,
      name: "Lemon Zest",
      css: "radial-gradient(circle, #FFD700, #FFFF00)",
      type: "warm",
    },
    {
      id: 375,
      name: "Tropical Paradise",
      css: "radial-gradient(circle, #00FF00, #00FFFF)",
      type: "cool",
    },
    {
      id: 376,
      name: "Flamingo Pink",
      css: "radial-gradient(circle, #FF1493, #FF69B4)",
      type: "warm",
    },
    {
      id: 377,
      name: "Electric Sunshine",
      css: "radial-gradient(circle, #FFFF00, #FFD700)",
      type: "warm",
    },
    {
      id: 378,
      name: "Skyline Dreams",
      css: "radial-gradient(circle, #00BFFF, #1E90FF)",
      type: "cool",
    },
    {
      id: 379,
      name: "Jungle Fever",
      css: "radial-gradient(circle, #228B22, #008000)",
      type: "cool",
    },
    {
      id: 380,
      name: "Fuchsia Frenzy",
      css: "radial-gradient(circle, #FF00FF, #FF1493)",
      type: "cool",
    },
    {
      id: 381,
      name: "Crimson Carnival",
      css: "radial-gradient(circle, #DC143C, #FFD700)",
      type: "warm",
    },
    {
      id: 382,
      name: "Oceanic Wonders",
      css: "radial-gradient(circle, #00CED1, #20B2AA)",
      type: "cool",
    },
    {
      id: 383,
      name: "Golden Radiance",
      css: "radial-gradient(circle, #FFD700, #FF6347)",
      type: "warm",
    },
    {
      id: 384,
      name: "Magenta Magic",
      css: "radial-gradient(circle, #FF00FF, #8A2BE2)",
      type: "cool",
    },
    {
      id: 385,
      name: "Emerald Dreams",
      css: "radial-gradient(circle, #00FF00, #008000)",
      type: "cool",
    },
    {
      id: 386,
      name: "Vivid Sunset",
      css: "radial-gradient(circle, #FF4500, #FFA500)",
      type: "warm",
    },
    {
      id: 387,
      name: "Purple Paradise",
      css: "radial-gradient(circle, #800080, #9370DB)",
      type: "cool",
    },
    {
      id: 388,
      name: "Turquoise Tranquility",
      css: "radial-gradient(circle, #00CED1, #87CEEB)",
      type: "cool",
    },
    {
      id: 389,
      name: "Golden Glimmer",
      css: "radial-gradient(circle, #FFD700, #FF6347)",
      type: "warm",
    },
    {
      id: 390,
      name: "Lilac Luster",
      css: "radial-gradient(circle, #C71585, #DA70D6)",
      type: "cool",
    },
    {
      id: 391,
      name: "Tropical Breeze",
      css: "radial-gradient(circle, #00FF00, #008000)",
      type: "cool",
    },
    {
      id: 392,
      name: "Blue Lagoon",
      css: "radial-gradient(circle, #00BFFF, #00CED1)",
      type: "cool",
    },
    {
      id: 393,
      name: "Ruby Royale",
      css: "radial-gradient(circle, #E32636, #FFD700)",
      type: "warm",
    },
    {
      id: 394,
      name: "Sunrise Serenity",
      css: "radial-gradient(circle, #FF4500, #FF6347)",
      type: "warm",
    },
    {
      id: 395,
      name: "Magenta Mirage",
      css: "radial-gradient(circle, #FF00FF, #8A2BE2)",
      type: "cool",
    },
    {
      id: 396,
      name: "Emerald Oasis",
      css: "radial-gradient(circle, #00FF00, #228B22)",
      type: "cool",
    },
    {
      id: 397,
      name: "Vivid Violets",
      css: "radial-gradient(circle, #8A2BE2, #800080)",
      type: "cool",
    },
    {
      id: 398,
      name: "Radiant Ruby",
      css: "radial-gradient(circle, #FF2400, #FFD700)",
      type: "warm",
    },
    {
      id: 399,
      name: "Turquoise Delight",
      css: "radial-gradient(circle, #40E0D0, #00CED1)",
      type: "cool",
    },
    {
      id: 400,
      name: "Copper Blaze",
      css: "radial-gradient(circle, #B87333, #FFD700)",
      type: "warm",
    },
  ],
};
