import React, { useState } from "react";
import moment from "moment";
import Input from "../../../components/Input/Input";
import "../utility.css";

const DateCalculator = () => {
  const [birthdate, setBirthdate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [age, setAge] = useState("");
  const [dateDifference, setDateDifference] = useState("");

  const calculateAge = (date) => {
    const duration = moment.duration(moment().diff(moment(date)));
    return {
      years: duration.years(),
      months: duration.months(),
      days: duration.days(),
      seconds: duration.asSeconds(),
    };
  };
  const calculateFullMonths = (date) => {
    return moment().diff(moment(date), "months");
  };

  const calculateFullDays = (date) => {
    return moment().diff(moment(date), "days");
  };

  const calculateDateDifferenceInMonths = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "months");
  };

  const calculateDateDifferenceInDays = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "days");
  };

  const calculateDateDifference = (startDate, endDate) => {
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));
    return {
      years: duration.years(),
      months: duration.months(),
      days: duration.days(),
      seconds: duration.asSeconds(),
    };
  };

  const handleBirthdateChange = (e) => {
    setBirthdate(e.target.value);
    const calculatedAge = calculateAge(e.target.value);
    setAge(calculatedAge);
  };

  const handleSecondDateChange = (e) => {
    setSecondDate(e.target.value);
    if (birthdate && e.target.value) {
      const diff = calculateDateDifference(birthdate, e.target.value);
      setDateDifference(diff);
    }
  };

  return (
    <div className="utility-container">
      <h2>Calculate Your Age</h2>
      <Input type="date" value={birthdate} onChange={handleBirthdateChange} />
      <div>
        {age && (
          <p>
            Age: {age.years} years, {age.months} months, {age.days} days,{" "}
            {parseInt(age.seconds, 10)} seconds
          </p>
        )}
        <h3>Total Months: {birthdate ? calculateFullMonths(birthdate) : ""}</h3>
        <h3>Total Days: {birthdate ? calculateFullDays(birthdate) : ""}</h3>
        {birthdate && secondDate && (
          <>
            <h3>
              Months Apart:{" "}
              {calculateDateDifferenceInMonths(birthdate, secondDate)}
            </h3>
            <h3>
              Days Apart: {calculateDateDifferenceInDays(birthdate, secondDate)}
            </h3>
          </>
        )}
      </div>

      <h2>Calculate Date Difference</h2>
      <Input type="date" value={birthdate} onChange={handleBirthdateChange} />
      <Input type="date" value={secondDate} onChange={handleSecondDateChange} />
      <div>
        {dateDifference && (
          <p>
            Difference: {dateDifference.years} years, {dateDifference.months}{" "}
            months, {dateDifference.days} days,{" "}
            {parseInt(dateDifference.seconds, 10)} seconds
          </p>
        )}
      </div>
    </div>
  );
};

export default DateCalculator;
