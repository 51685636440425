import React, { useState } from "react";
import "./RegexTester.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";

const RegexTester = () => {
  const [regex, setRegex] = useState("");
  const [testString, setTestString] = useState("");
  const [matchInfo, setMatchInfo] = useState(null);

  const handleBack = () => {
    setRegex("");
    setTestString("");
    setMatchInfo(null);
  };

  const handleRegexChange = (e) => setRegex(e.target.value);
  const handleTestStringChange = (e) => setTestString(e.target.value);

  const testRegex = () => {
    try {
      const re = new RegExp(regex);
      const match = re.exec(testString);
      setMatchInfo(match ? JSON.stringify(match, null, 2) : "No match found.");
    } catch (e) {
      setMatchInfo("Invalid regular expression.");
    }
  };

  return (
    <div>
      <BackButton
        onBack={regex || testString || matchInfo ? handleBack : null}
        title="Regex Tester/Debugger"
      />

      <Input
        type="text"
        value={regex}
        onChange={handleRegexChange}
        placeholder="Enter your regex"
      />
      <Input
        type="text"
        value={testString}
        onChange={handleTestStringChange}
        placeholder="Enter string to test"
      />
      <Button onClick={testRegex}>Test Regex</Button>
      {matchInfo && (
        <div>
          <h3>Match Information:</h3>
          <pre>{matchInfo}</pre>
        </div>
      )}
    </div>
  );
};

export default RegexTester;
