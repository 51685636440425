import "./ColorTools.css";
import React, { useState } from "react";
import { SketchPicker } from "react-color"; // If you're using react-color library
import "../utility.css";

const ColorTools = () => {
  const [color, setColor] = useState("#ffffff");
  const [colorHistory, setColorHistory] = useState([]);

  const handleColorChange = (color) => {
    setColor(color.hex);
    if (!colorHistory.includes(color.hex)) {
      setColorHistory([...colorHistory, color.hex]);
    }
  };

  const hexToRGB = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div className="utility-container">
      <SketchPicker color={color} onChangeComplete={handleColorChange} />
      <input
        type="text"
        value={color}
        onChange={(e) => handleColorChange({ hex: e.target.value })}
        style={{ width: "100%", padding: "10px", marginTop: "10px" }}
      />
      <div>RGB: {hexToRGB(color)} </div>
      <div style={{ marginTop: "20px" }}>
        <h3>Color History</h3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {colorHistory.map((c, index) => (
            <div
              key={index}
              style={{
                backgroundColor: c,
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColorTools;
