import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

const CronJobParser = () => {
  const [cronExpression, setCronExpression] = useState("");
  const [parsedCron, setParsedCron] = useState(null);

  const handleCronExpressionChange = (e) => {
    setCronExpression(e.target.value);
  };

  const parseCronExpression = () => {
    const parts = cronExpression.split(" ");
    if (parts.length !== 5) {
      alert("Invalid cron expression. Please use the format: * * * * *");
      return;
    }

    setParsedCron({
      minute: parts[0],
      hour: parts[1],
      dayOfMonth: parts[2],
      month: parts[3],
      dayOfWeek: parts[4],
    });
  };

  return (
    <div>
      <h2>Cron Job Parser</h2>
      <Input
        type="text"
        value={cronExpression}
        onChange={handleCronExpressionChange}
        placeholder="Enter cron expression (e.g., * * * * *)"
      />
      <Button onClick={parseCronExpression}>Parse Cron Expression</Button>
      {parsedCron && (
        <div>
          <h3>Parsed Cron Expression:</h3>
          <p>Minute: {parsedCron.minute}</p>
          <p>Hour: {parsedCron.hour}</p>
          <p>Day of Month: {parsedCron.dayOfMonth}</p>
          <p>Month: {parsedCron.month}</p>
          <p>Day of Week: {parsedCron.dayOfWeek}</p>
        </div>
      )}
    </div>
  );
};

export default CronJobParser;
