import React, { useState } from "react";
import { transform } from "html-to-jsx";
import "./HtmlToJsx.css";

const HtmlToJsx = () => {
  const [htmlInput, setHtmlInput] = useState("");
  const [jsxOutput, setJsxOutput] = useState("");

  const convertHtmlToJsx = async () => {
    try {
      const jsx = await transform(htmlInput);
      setJsxOutput(jsx);
    } catch (error) {
      setJsxOutput("Error converting HTML to JSX");
    }
  };

  return (
    <div className="html-to-jsx-converter">
      <textarea
        value={htmlInput}
        onChange={(e) => setHtmlInput(e.target.value)}
        placeholder="Paste your HTML here..."
      />
      <button onClick={convertHtmlToJsx}>Convert to JSX</button>
      <textarea
        value={jsxOutput}
        readOnly
        placeholder="JSX output will appear here..."
      />
    </div>
  );
};

export default HtmlToJsx;
