import React, { useState } from "react";
import "./SvgToCss.css";

const SvgToCss = () => {
  const [svgInput, setSvgInput] = useState("");
  const [cssOutput, setCssOutput] = useState("");

  const convertSvgToCss = () => {
    // This is a basic conversion. A more robust solution would use a parser.
    const cssShape = svgInput
      .replace(/<\/?svg[^>]*>/g, "")
      .replace(/\s+/g, " ")
      .trim();

    const css = `
.shape {
  width: 100px;
  height: 100px;
  background: black;
  -webkit-mask: url('data:image/svg+xml,${encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg">${cssShape}</svg>`)}') no-repeat center / contain;
  mask: url('data:image/svg+xml,${encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg">${cssShape}</svg>`)}') no-repeat center / contain;
}`;

    setCssOutput(css);
  };

  return (
    <div className="svg-to-css-converter">
      <textarea
        value={svgInput}
        onChange={(e) => setSvgInput(e.target.value)}
        placeholder="Paste your SVG code here..."
      />
      <button onClick={convertSvgToCss}>Convert to CSS</button>
      <textarea
        value={cssOutput}
        readOnly
        placeholder="CSS output will appear here..."
      />
    </div>
  );
};

export default SvgToCss;
