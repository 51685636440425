import React, { useState } from "react";
import "./ColorConverter.css";
import BackButton from "../../../components/BackButton/BackButton";
import Input from "../../../components/Input/Input";

const ColorConverter = () => {
  const [color, setColor] = useState("#000000");
  const [rgbColor, setRgbColor] = useState("rgb(0, 0, 0)");
  const [hslColor, setHslColor] = useState("hsl(0, 0%, 0%)");

  const handleBack = () => {
    setColor("#000000");
    setRgbColor("rgb(0, 0, 0)");
    setHslColor("hsl(0, 0%, 0%)");
  };

  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const hexToHsl = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    return `hsl(${Math.round(h * 360)}, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%)`;
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setColor(newColor);
    setRgbColor(hexToRgb(newColor));
    setHslColor(hexToHsl(newColor));
  };

  return (
    <div className="color-converter">
      <BackButton
        onBack={color !== "#000000" ? handleBack : null}
        title="Color Converter"
      />

      <Input type="color" value={color} onChange={handleColorChange} />
      <div className="color-values">
        <p>HEX: {color}</p>
        <p>RGB: {rgbColor}</p>
        <p>HSL: {hslColor}</p>
      </div>
    </div>
  );
};

export default ColorConverter;
