import React, { useState } from "react";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import "../utility.css";

const CSSMinifier = () => {
  const [inputCSS, setInputCSS] = useState("");
  const [minifiedCSS, setMinifiedCSS] = useState("");

  const handleBack = () => {
    setInputCSS("");
    setMinifiedCSS("");
  };

  const minifyCSS = (css) => {
    return css
      .replace(/\s+/g, " ")
      .replace(/\/\*.*?\*\//g, "")
      .replace(/\s?\{\s?/g, "{")
      .replace(/\s?\}\s?/g, "}")
      .replace(/\s?:\s?/g, ":")
      .replace(/\s?;?\s?/g, ";")
      .replace(/\s?,?\s?/g, ",");
  };

  const handleMinify = () => {
    const minified = minifyCSS(inputCSS);
    setMinifiedCSS(minified);
  };

  return (
    <div className="utility-container">
      <BackButton
        onBack={inputCSS || minifiedCSS ? handleBack : null}
        title="CSS Minifier"
      />

      <Textarea
        value={inputCSS}
        onChange={(e) => setInputCSS(e.target.value)}
        placeholder="Enter CSS here"
      />
      <Button onClick={handleMinify}>Minify CSS</Button>
      {minifiedCSS && (
        <Textarea
          value={minifiedCSS}
          readOnly
          placeholder="Minified CSS will appear here"
        />
      )}
    </div>
  );
};

export default CSSMinifier;
