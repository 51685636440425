import React, { useState } from "react";
import YAML from "js-yaml";
import "./JsonYamlConverter.css";
import Button from "../../../components/Button/Button";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

const JsonYamlConverter = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [isJson, setIsJson] = useState(true);

  const handleBack = () => {
    setInput("");
    setOutput("");
    setIsJson(true);
  };

  const convert = () => {
    try {
      if (isJson) {
        const jsonObj = JSON.parse(input);
        setOutput(YAML.dump(jsonObj));
      } else {
        const yamlObj = YAML.load(input);
        setOutput(JSON.stringify(yamlObj, null, 2));
      }
    } catch (error) {
      setOutput("Error: Invalid input");
    }
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={input || output ? handleBack : null}
        title={isJson ? "JSON to YAML Converter" : "YAML to JSON Converter"}
      />

      <div className="input-container">
        <Textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={isJson ? "Enter JSON here..." : "Enter YAML here..."}
        />
      </div>
      <div className="controls">
        <Button onClick={() => setIsJson(!isJson)}>
          Switch to {isJson ? "YAML to JSON" : "JSON to YAML"}
        </Button>
        <Button onClick={convert}>Convert</Button>
      </div>
      {output && (
        <div className="output-container">
          <Textarea
            value={output}
            readOnly
            placeholder="Conversion result..."
          />
        </div>
      )}
    </div>
  );
};

export default JsonYamlConverter;
