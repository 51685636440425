import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "./ThemeContext";
import { database } from "./firebase";
import { ref, runTransaction } from "firebase/database";
// import Header from './pages/header/header';
import Sidebar from "./pages/sidebar/sidebar";
import routes from "./routes/routes";

import RouteWithFooter from "./routes/RouteWithFooter";
import Home from "./pages/home/home";
import NotFound from "./pages/NotFound/NotFound";

/**
 * Description
 * @author Raghav Aadithya
 * @date 2024-01-14
 * @return {any}
 */
function App() {
  useEffect(() => {
    // Function to update view count
    const dbPath =
      window.location.hostname === "localhost"
        ? "views/localhost"
        : "views/counter";

    const updateViewCount = () => {
      const countRef = ref(database, dbPath);
      runTransaction(countRef, (currentValue) => {
        if (currentValue === null) {
          return 1;
        } else {
          return Number(currentValue) + 1;
        }
      });
    };

    updateViewCount(); // Update the view count when the app loads
  }, []);
  return (
    <ThemeProvider>
      <Router>
        <div style={{ marginBottom: "10px" }}>
          <Sidebar />
        </div>
        <Routes>
          <Route
            path={"/home"}
            element={
              <RouteWithFooter
                title={"Dev Essentials"}
                description={"Home Page of Dev essentials"}
                component={Home}
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to="/404" replace element={<NotFound />} />}
          />
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <RouteWithFooter
                  title={route.title}
                  description={route.description}
                  component={route.component}
                  howToUse={route.howToUse}
                  apiEndpoint={route.apiEndpoint}
                />
              }
            />
          ))}
          <Route path="/" element={<Navigate replace to="/home" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
