import React, { useState } from "react";
import axios from "axios";
import "./ResizeImageByPercentage.css";
import Select from "../../../components/Select/Select";
import Image from "../../../components/Image/Image";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import serviceConstants from "../../../constants/serviceConstants";
import "../image.css";

const ResizeImageByPercentage = () => {
  const [imageFile, setImageFile] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [format, setFormat] = useState("jpeg");
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleBack = () => {
    setImageFile(null);
    setResizedImage(null);
    setIsLoading(false);
    setPercentage("");
    setFormat("jpeg");
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleResizeImage = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    formData.append("percentage", percentage);
    formData.append("format", format);

    try {
      const response = await axios.post(
        `${baseUrl}/image/resize-percentage`,
        formData,
        { responseType: "blob" },
      );
      setResizedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error resizing image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadResizedImage = () => {
    const link = document.createElement("a");
    link.href = resizedImage;
    link.setAttribute("download", `resized_image.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-utility-container">
      <BackButton
        onBack={imageFile ? handleBack : null}
        title="Resize Image By Percentage"
      />

      {!imageFile ? (
        <Input type="file" accept="image/*" onChange={handleImageChange} />
      ) : (
        <div>{imageFile.name}</div>
      )}

      {imageFile && (
        <>
          <Input
            type="number"
            value={percentage}
            onChange={handlePercentageChange}
            placeholder="Resize Percentage"
          />
          <Select
            options={formatOptions}
            value={format}
            onChange={handleFormatChange}
            placeholder={"Image Format"}
          />
          <Button
            onClick={handleResizeImage}
            disabled={!imageFile || isLoading}
          >
            Resize Image
          </Button>
        </>
      )}

      {resizedImage && (
        <div>
          <Image src={resizedImage} alt="Resized" />
          <Button onClick={downloadResizedImage}>Download Resized Image</Button>
        </div>
      )}
    </div>
  );
};

export default ResizeImageByPercentage;
