import React, { useState } from "react";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import BackButton from "../../../components/BackButton/BackButton";
import "../utility.css";

const CurlToCode = () => {
  const [curlCommand, setCurlCommand] = useState("");
  const [convertedCode, setConvertedCode] = useState("");
  const [language, setLanguage] = useState("python");

  const handleBack = () => {
    setCurlCommand("");
    setConvertedCode("");
    setLanguage("python");
  };

  const languageOptions = [
    { value: "python", label: "Python (requests)" },
    { value: "javascript", label: "JavaScript (fetch)" },
    { value: "php", label: "PHP (cURL)" },
    { value: "go", label: "Go" },
    { value: "ruby", label: "Ruby" },
  ];

  const parseHeaders = (headerString) => {
    const headers = {};
    headerString.split("-H").forEach((header) => {
      const match = header.match(/'([^:]+):\s*(.+)'/);
      if (match) {
        headers[match[1].trim()] = match[2].trim();
      }
    });
    return headers;
  };

  const parseBody = (bodyString) => {
    if (bodyString.startsWith("'") && bodyString.endsWith("'")) {
      bodyString = bodyString.slice(1, -1);
    }
    try {
      return JSON.parse(bodyString);
    } catch (e) {
      return bodyString;
    }
  };

  const convertCurl = () => {
    const urlMatch = curlCommand.match(/'(https?:\/\/[^']+)'/);
    const url = urlMatch ? urlMatch[1] : "";
    const method = curlCommand.includes("-X")
      ? curlCommand.split("-X")[1].split(" ")[1]
      : "GET";
    const headers = parseHeaders(curlCommand);
    const bodyMatch = curlCommand.match(/-d '(.+)'/);
    const body = bodyMatch ? parseBody(bodyMatch[1]) : null;

    let code = "";

    switch (language) {
      case "python":
        code = `import requests

url = '${url}'
headers = ${JSON.stringify(headers, null, 4)}
${body ? `body = ${JSON.stringify(body, null, 4)}` : ""}

response = requests.${method.toLowerCase()}(url${Object.keys(headers).length > 0 ? ", headers=headers" : ""}${body ? ", json=body" : ""})

print(response.status_code)
print(response.json())`;
        break;

      case "javascript":
        code = `fetch('${url}', {
  method: '${method}',
  headers: ${JSON.stringify(headers, null, 2)},
  ${body ? `body: JSON.stringify(${JSON.stringify(body, null, 2)})` : ""}
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error('Error:', error));`;
        break;

      case "php":
        code = `<?php
$curl = curl_init();

curl_setopt_array($curl, [
    CURLOPT_URL => '${url}',
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => '${method}',
    ${
      Object.keys(headers).length > 0
        ? `CURLOPT_HTTPHEADER => [
        ${Object.entries(headers)
          .map(([key, value]) => `'${key}: ${value}'`)
          .join(",\n        ")}
    ],`
        : ""
    }
    ${body ? `CURLOPT_POSTFIELDS => '${JSON.stringify(body)}'` : ""}
]);

$response = curl_exec($curl);

curl_close($curl);
echo $response;`;
        break;

      case "go":
        code = `package main

import (
    "fmt"
    "io/ioutil"
    "log"
    "net/http"
    ${body ? `"strings"` : ""}
)

func main() {
    url := "${url}"
    method := "${method}"

    ${body ? `payload := strings.NewReader(\`${JSON.stringify(body)}\`)` : ""}
    client := &http.Client{}
    req, err := http.NewRequest(method, url, ${body ? "payload" : "nil"})
    if err != nil {
        log.Fatal(err)
    }

    ${Object.entries(headers)
      .map(([key, value]) => `req.Header.Add("${key}", "${value}")`)
      .join("\n    ")}

    resp, err := client.Do(req)
    if err != nil {
        log.Fatal(err)
    }
    defer resp.Body.Close()

    body, err := ioutil.ReadAll(resp.Body)
    if err != nil {
        log.Fatal(err)
    }

    fmt.Println(string(body))
}`;
        break;

      case "ruby":
        code = `require 'uri'
require 'net/http'
require 'json'

url = URI('${url}')
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = (url.scheme == 'https')

request = Net::HTTP::${method.capitalize}.new(url)
${Object.entries(headers)
  .map(([key, value]) => `request['${key}'] = '${value}'`)
  .join("\n")}
${body ? `request.body = ${JSON.stringify(body)}` : ""}

response = http.request(request)
puts response.read_body`;
        break;

      default:
        code = "Unsupported language";
    }

    setConvertedCode(code);
  };

  return (
    <div className="utility-container">
      <BackButton
        onBack={curlCommand || convertedCode ? handleBack : null}
        title="cURL to Code Converter"
      />

      <Textarea
        value={curlCommand}
        onChange={(e) => setCurlCommand(e.target.value)}
        placeholder="Enter your cURL command here"
      />
      <Select
        options={languageOptions}
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
      />
      <Button onClick={convertCurl}>Convert</Button>
      {convertedCode && (
        <Textarea
          value={convertedCode}
          readOnly
          placeholder="Converted code will appear here"
        />
      )}
    </div>
  );
};

export default CurlToCode;
