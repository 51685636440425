import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import "./SvgToPng.css";
import Button from "../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import "../image.css";

const SvgToPng = () => {
  const [svgHtml, setSvgHtml] = useState("");
  const [format, setFormat] = useState("png");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [convertedImage, setConvertedImage] = useState(null);
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleBack = () => {
    setSvgHtml("");
    setFormat("png");
    setWidth("");
    setHeight("");
    setConvertedImage(null);
  };

  const handleConvert = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/image/svg-to-image`,
        {
          svgHtml,
          format,
          options: {
            width: width ? parseInt(width) : undefined,
            height: height ? parseInt(height) : undefined,
          },
        },
        { responseType: "blob" },
      );
      setConvertedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error converting SVG to image:", error);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = convertedImage;
    link.download = `converted.${format}`;
    link.click();
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, staggerChildren: 0.2 },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="image-utility-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <BackButton
        onBack={svgHtml || convertedImage ? handleBack : null}
        title="Convert SVG to PNG"
      />

      <motion.textarea
        value={svgHtml}
        onChange={(e) => setSvgHtml(e.target.value)}
        placeholder="Enter SVG HTML code here"
        variants={childVariants}
      />
      <motion.div className="options" variants={childVariants}>
        <motion.select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
        >
          <option value="png">PNG</option>
          <option value="jpeg">JPEG</option>
        </motion.select>
        <motion.input
          type="number"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
          placeholder="Width"
        />
        <motion.input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          placeholder="Height"
        />
      </motion.div>
      <Button onClick={handleConvert}>Convert</Button>
      {convertedImage && (
        <motion.div
          className="result"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.h3 variants={childVariants}>Converted Image:</motion.h3>
          <motion.img
            src={convertedImage}
            alt="Converted"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <Button onClick={handleDownload}>Download</Button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default SvgToPng;
