import React, { useState } from "react";
import { makeApiCall } from "../../utils/api";
import PropTypes from "prop-types";
import BackButton from "../../components/BackButton/BackButton";

const GenerateRegex = ({ apiEndpoint, title, description }) => {
  console.log(apiEndpoint, title, description);
  const [input, setInput] = useState("");
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    setInput("");
    setResult(null);
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await makeApiCall(apiEndpoint, { description: input });
      setResult(data);
    } catch (error) {
      setResult({ error: "Failed to generate regex. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="generate-regex-container">
      <BackButton onBack={input || result ? handleBack : null} title={title} />
      <p>{description}</p>
      <form onSubmit={handleSubmit}>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter a description of the pattern you want to match"
          rows="4"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Generating..." : "Generate Regex"}
        </button>
      </form>
      {result && (
        <div className="result">
          <h2>Generated Regex:</h2>
          <pre>{result.regex}</pre>
          <h3>Explanation:</h3>
          <p>{result.explanation}</p>
        </div>
      )}
    </div>
  );
};

GenerateRegex.propTypes = {
  apiEndpoint: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default GenerateRegex;
