import React, { useState, useEffect } from "react";
import ErrorBoundary from "../ErrorBoundary";
import Footer from "../pages/footer/Footer";
import SEO from "../pages/seo/seo";
import logo from "../assets/logo192.png";
import PropTypes from "prop-types";
import { FiHelpCircle, FiX, FiChevronUp } from "react-icons/fi";
import "./RouteWithFooter.css";

const RouteWithFooter = ({
  title,
  description,
  component: Component,
  howToUse,
  apiEndpoint,
}) => {
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    if (howToUse) {
      const steps = howToUse.trim().split("\n");
      const initialExpandedState = steps.reduce((acc, _, index) => {
        acc[index] = true;
        return acc;
      }, {});
      setExpandedSections(initialExpandedState);
    }
  }, [howToUse]);

  const toggleInstructions = () => {
    setIsInstructionsOpen(!isInstructionsOpen);
  };

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderInstructions = () => {
    if (!howToUse) return null;

    const steps = howToUse
      .trim()
      .split("\n")
      .map((step) => step.trim());
    return steps.map((step, index) => {
      const [title, ...content] = step.split(". ");
      return (
        <div key={index} className="instruction-step">
          <div className="step-header" onClick={() => toggleSection(index)}>
            <span className="step-number">{title}</span>
            <FiChevronUp className={expandedSections[index] ? "rotated" : ""} />
          </div>
          {expandedSections[index] && (
            <div className="step-content">{content.join(". ")}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="route-with-footer-container">
      <SEO title={title} description={description} imageUrl={logo} />
      <ErrorBoundary>
        <div className={`main-content ${isInstructionsOpen ? "shifted" : ""}`}>
          <Component
            title={title}
            description={description}
            howToUse={howToUse}
            apiEndpoint={apiEndpoint}
          />
        </div>
        {howToUse && (
          <>
            <button
              className={`instructions-toggle ${isInstructionsOpen ? "open" : ""}`}
              onClick={toggleInstructions}
              aria-label={
                isInstructionsOpen ? "Close instructions" : "Open instructions"
              }
            >
              {isInstructionsOpen ? <FiX /> : <FiHelpCircle />}
            </button>
            <div
              className={`instructions-panel ${isInstructionsOpen ? "open" : ""}`}
            >
              <h2>How to Use This Tool</h2>
              <div className="instructions-content">{renderInstructions()}</div>
            </div>
          </>
        )}
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

RouteWithFooter.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  howToUse: PropTypes.string,
  apiEndpoint: PropTypes.string,
};

export default RouteWithFooter;
