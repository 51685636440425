import React, { useState } from "react";
import axios from "axios";
import "./ImageCompressor.css";
import Select from "../../../components/Select/Select";
import Image from "../../../components/Image/Image";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import serviceConstants from "../../../constants/serviceConstants";
import "../image.css";

const ImageCompressor = () => {
  const [imageFile, setImageFile] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [resizePercentage, setResizePercentage] = useState("");
  const [targetSize, setTargetSize] = useState("");
  const [targetSizeUnit, setTargetSizeUnit] = useState("KB");
  const [outputFormat, setOutputFormat] = useState("jpeg");
  const formatOptions = serviceConstants.ImageFormatOptions;
  const sizeOptions = serviceConstants.ImageSizeOptions;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2000"
      : "https://devessentialsbackend.onrender.com";

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleBack = () => {
    setImageFile(null);
    setCompressedImage(null);
    setWidth("");
    setHeight("");
    setResizePercentage("");
    setTargetSize("");
    setTargetSizeUnit("KB");
    setOutputFormat("jpeg");
  };

  const handleCompressImage = async () => {
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("resizePercentage", resizePercentage);
    formData.append("targetSize", targetSize);
    formData.append("targetSizeUnit", targetSizeUnit);
    formData.append("outputFormat", outputFormat);

    try {
      const response = await axios.post(`${baseUrl}/image/compress`, formData, {
        responseType: "blob",
      });
      setCompressedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const downloadCompressedImage = () => {
    const link = document.createElement("a");
    link.href = compressedImage;
    link.setAttribute("download", `compressed_image.${outputFormat}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-utility-container">
      <BackButton
        onBack={imageFile ? handleBack : null}
        title="Image Compressor"
      />

      {!imageFile ? (
        <Input type="file" accept="image/*" onChange={handleImageChange} />
      ) : (
        <div>{imageFile.name}</div>
      )}

      {imageFile && (
        <>
          <Input
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            placeholder="Width"
          />
          <Input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="Height"
          />
          <Input
            type="number"
            value={resizePercentage}
            onChange={(e) => setResizePercentage(e.target.value)}
            placeholder="Resize Percentage"
          />
          <Input
            type="number"
            value={targetSize}
            onChange={(e) => setTargetSize(e.target.value)}
            placeholder="Target Size"
          />
          <Select
            options={sizeOptions}
            value={targetSizeUnit}
            onChange={(e) => setTargetSizeUnit(e.target.value)}
            placeholder={"Image Size"}
          />
          <Select
            options={formatOptions}
            value={outputFormat}
            onChange={(e) => setOutputFormat(e.target.value)}
            placeholder={"Image Format"}
          />
          <Button className="image-button" onClick={handleCompressImage}>
            Compress Image
          </Button>
        </>
      )}

      {compressedImage && (
        <div>
          <Image
            className="image-result"
            src={compressedImage}
            alt="Compressed"
          />
          <Button onClick={downloadCompressedImage}>
            Download Compressed Image
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageCompressor;
