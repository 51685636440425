import React, { useState } from "react";
import Papa from "papaparse";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

function CSVToJsonConverter() {
  const [jsonData, setJsonData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBack = () => {
    setJsonData(null);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      Papa.parse(file, {
        complete: (result) => {
          setJsonData(JSON.stringify(result.data, null, 2));
        },
        header: true,
      });
    }
  };

  const downloadJsonFile = () => {
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "converted.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={selectedFile || jsonData ? handleBack : null}
        title="CSV to JSON Converter"
      />

      {!selectedFile ? (
        <Input type="file" onChange={handleFileChange} accept=".csv" />
      ) : (
        <div>{selectedFile.name}</div>
      )}

      {jsonData && (
        <>
          <Button onClick={downloadJsonFile}>Download JSON</Button>
          <pre>{jsonData}</pre>
        </>
      )}
    </div>
  );
}

export default CSVToJsonConverter;
