import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "./ImageCropper.css";
import "react-image-crop/dist/ReactCrop.css";
import Image from "../../../components/Image/Image";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import "../image.css";

const ImageCropper = () => {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(file);
    }
  };

  const handleBack = () => {
    setSrc(null);
    setCrop({ aspect: 16 / 9 });
    setCroppedImageUrl(null);
    setSelectedFile(null);
  };

  const onImageLoaded = () => {
    console.log("this dimensions");
    // This is important to set up the crop dimensions based on the image dimensions
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (src && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(src, crop, "newFile.jpeg");
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(croppedImageUrl);
        resolve(window.URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  }

  return (
    <div className="image-utility-container">
      <BackButton
        onBack={selectedFile ? handleBack : null}
        title="Image Cropper"
      />

      {!selectedFile ? (
        <Input type="file" accept="image/*" onChange={onSelectFile} />
      ) : (
        <div>{selectedFile.name}</div>
      )}

      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}

      {croppedImageUrl && (
        <div>
          <Image alt="Cropped" src={croppedImageUrl} />
          <a href={croppedImageUrl} download>
            Download Cropped Image
          </a>
        </div>
      )}
    </div>
  );
};

export default ImageCropper;
