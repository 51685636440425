import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./CompressPDFs.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:2000"
    : "https://devessentialsbackend.onrender.com";
const CompressPDFs = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [compressedPdfBlob, setCompressedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleCompressPDF = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    try {
      const response = await axios.post(`${baseUrl}/pdf/compress`, formData, {
        responseType: "blob",
      });
      setCompressedPdfBlob(
        new Blob([response.data], { type: "application/pdf" }),
      );
    } catch (error) {
      console.error("Error compressing PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCompressedPdf = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(compressedPdfBlob);
    link.setAttribute("download", "compressed_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div className="pdf-utility-container">
      <h2>Compress PDF</h2>
      <Input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        // className="file-Input"
      />
      <Button onClick={handleCompressPDF} disabled={!pdfFile || isLoading}>
        Compress PDF
      </Button>
      {compressedPdfBlob && (
        <div>
          <div className="pdf-container">
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={compressedPdfBlob}
              className="pdf-document"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page">
                  <Page key={index} pageNumber={index + 1} width={100} />
                </div>
              ))}
            </Document>
          </div>
          <Button onClick={downloadCompressedPdf}>
            Download Compressed PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default CompressPDFs;
