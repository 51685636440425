/* eslint-disable react/no-unknown-property */
import React, { useState, useRef, useEffect } from "react";
import "./ColorPicker.css";
import "../utility.css";
/**
 * Description
 * @return {any}
 */
export default function ColorPicker() {
  const [imageSrc, setImageSrc] = useState("");
  const [color, setColor] = useState("");
  const canvasRef = useRef(null);
  useEffect(() => {
    if (imageSrc) {
      loadImageOnCanvas();
    }
  }, [imageSrc]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const loadImageOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);
    };

    image.src = imageSrc;
  };

  const pickColor = (event) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const pixel = ctx.getImageData(x, y, 1, 1).data;

    setColor(`rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`);
  };

  return (
    <div className="utility-container">
      <input type="file" onChange={handleImageChange} />
      {imageSrc && (
        <div>
          <canvas
            ref={canvasRef}
            onClick={pickColor}
            onLoad={loadImageOnCanvas}
            className="colorPickerCursor"
          />
          <div
            style={{
              backgroundColor: color,
              width: "100px",
              height: "100px",
              marginTop: "10px",
            }}
          >
            {color}
          </div>
        </div>
      )}
    </div>
  );
}
