import React, { useState } from "react";
import { diff } from "json-diff";
import DOMPurify from "dompurify";
import Button from "../../../components/Button/Button";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";

const JsonDiffChecker = () => {
  const [json1, setJson1] = useState("");
  const [json2, setJson2] = useState("");
  const [diffResult, setDiffResult] = useState("");

  const handleBack = () => {
    setJson1("");
    setJson2("");
    setDiffResult("");
  };

  const formatDiffOutput = (diffOutput) => {
    return JSON.stringify(diffOutput, null, 2)
      .replace(/"__old":/g, '<span class="diff-old">-')
      .replace(/"__new":/g, '<span class="diff-new">+')
      .replace(/"__deleted":/g, '<span class="diff-deleted">*deleted*')
      .replace(/"__added":/g, '<span class="diff-added">*added*')
      .replace(/\n/g, "<br />")
      .replace(/ /g, "&nbsp;");
  };

  const calculateDiff = () => {
    try {
      const obj1 = JSON.parse(json1);
      const obj2 = JSON.parse(json2);
      const diffOutput = diff(obj1, obj2);
      if (!diffOutput) {
        setDiffResult("No differences found.");
      } else {
        const formattedDiff = formatDiffOutput(diffOutput);
        const sanitizedDiff = DOMPurify.sanitize(formattedDiff);
        setDiffResult(sanitizedDiff);
      }
    } catch (error) {
      setDiffResult("Error parsing JSON: " + error.message);
    }
  };

  return (
    <div className="json-utility-container">
      <BackButton
        onBack={json1 || json2 || diffResult ? handleBack : null}
        title="JSON Diff Checker"
      />

      <Textarea
        value={json1}
        onChange={(e) => setJson1(e.target.value)}
        placeholder="Enter JSON 1 here"
      />
      <Textarea
        value={json2}
        onChange={(e) => setJson2(e.target.value)}
        placeholder="Enter JSON 2 here"
      />
      <Button onClick={calculateDiff}>Compare</Button>
      <div
        className="diff-results"
        dangerouslySetInnerHTML={{ __html: diffResult }}
      />
    </div>
  );
};

export default JsonDiffChecker;
