import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import routes from "../../routes/routes";
import {
  FiHome,
  FiFile,
  FiImage,
  FiCode,
  FiTool,
  FiLock,
  FiSearch,
  FiChevronDown,
  FiChevronRight,
} from "react-icons/fi"; // FiMenu, FiChevronLeft,
import "./sidebar.css";
import logo from "../../assets/logo192.png";

const getIcon = (type) => {
  switch (type) {
    case "PDF utility":
      return <FiFile />;
    case "Image utility":
      return <FiImage />;
    case "JSON utility":
      return <FiCode />;
    case "utility":
      return <FiTool />;
    case "encoder":
      return <FiLock />;
    default:
      return <FiHome />;
  }
};

const Sidebar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsExpanded(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const groupedRoutes = routes.reduce((acc, route) => {
    if (!acc[route.type]) acc[route.type] = [];
    acc[route.type].push(route);
    return acc;
  }, {});

  const filteredRoutes = Object.entries(groupedRoutes).reduce(
    (acc, [type, typeRoutes]) => {
      const filteredTypeRoutes = typeRoutes.filter(
        (route) =>
          route.menuTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
          route.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      if (filteredTypeRoutes.length > 0) {
        acc[type] = filteredTypeRoutes;
      }
      return acc;
    },
    {},
  );

  // const toggleSidebar = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <nav
      className={`sidebar ${isMobile ? "mobile" : ""} ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <div className="sidebar-header">
        <div className="logo-container">
          <img src={logo} width={30} height={30} alt="Dev Essentials" />
          <span className="logo-text">Dev Essentials</span>
        </div>
        {/* <button className="toggle-btn" onClick={toggleSidebar}>
          {isExpanded ? <FiChevronLeft /> : <FiMenu />}
        </button> */}
      </div>
      <div className="search-bar">
        <FiSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {Object.entries(filteredRoutes).map(([type, typeRoutes]) => (
        <div
          key={type}
          className={`route-group ${expandedCategories[type] ? "expanded" : ""}`}
        >
          <div className="category-header" onClick={() => toggleCategory(type)}>
            <h3>{type}</h3>
            {expandedCategories[type] ? <FiChevronDown /> : <FiChevronRight />}
          </div>
          <ul className="sub-menu">
            {typeRoutes.map((route) => (
              <li key={route.path}>
                <NavLink to={route.path} title={route.title}>
                  {getIcon(route.type)}
                  <span>{route.menuTitle}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
};

export default Sidebar;
