import React, { useState } from "react";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";

const HexAsciiConverter = () => {
  const [hex, setHex] = useState("");
  const [ascii, setAscii] = useState("");

  const hexToAscii = (hex) => {
    return (
      hex
        .match(/.{1,2}/g)
        ?.map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("") || ""
    );
  };

  const asciiToHex = (ascii) => {
    return ascii
      .split("")
      .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("");
  };

  const handleHexToAscii = () => {
    setAscii(hexToAscii(hex));
  };

  const handleAsciiToHex = () => {
    setHex(asciiToHex(ascii));
  };

  return (
    <div className="hex-ascii-converter-container">
      <h2>Hex to ASCII / ASCII to Hex Converter</h2>
      <div>
        <Textarea
          value={hex}
          onChange={(e) => setHex(e.target.value)}
          placeholder="Enter hexadecimal"
        />
        <Button onClick={handleHexToAscii}>Hex to ASCII</Button>
      </div>
      <div>
        <Textarea
          value={ascii}
          onChange={(e) => setAscii(e.target.value)}
          placeholder="Enter ASCII"
        />
        <Button onClick={handleAsciiToHex}>ASCII to Hex</Button>
      </div>
    </div>
  );
};

export default HexAsciiConverter;
