import React, { useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFHandler.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import BackButton from "../../../components/BackButton/BackButton";
import "../pdf.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:2000"
    : "https://devessentialsbackend.onrender.com";
const PDFHandler = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewPdfFile, setPreviewPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [previewnumPages, setpreviewNumPages] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [splitPages, setSplitPages] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setPdfFile(e.dataTransfer.files[0]);
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
    setSelectedPages([]);
    setSplitPages("");
  };

  const handleBack = () => {
    setPdfFile(null);
    setPreviewUrl("");
    setPreviewPdfFile(null);
    setNumPages(null);
    setpreviewNumPages(null);
    setSelectedPages([]);
    setSplitPages("");
    setIsLoading(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onDocumentLoadSuccesspreview = ({ numPages }) => {
    setpreviewNumPages(numPages);
  };
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = previewUrl;
    link.setAttribute("download", "split_pdf.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlePageSelect = (pageNumber) => {
    const updatedSelectedPages = selectedPages.includes(pageNumber)
      ? selectedPages.filter((page) => page !== pageNumber)
      : [...selectedPages, pageNumber];
    setSelectedPages(updatedSelectedPages);

    const updatedSplitPages = updatedSelectedPages
      .sort((a, b) => a - b)
      .map((page) => page + 1)
      .join(",");
    setSplitPages(updatedSplitPages);
  };

  const handleSplitPdf = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);
    formData.append(
      "pageNumbers",
      JSON.stringify(selectedPages.map((page) => page)),
    );

    try {
      const response = await axios.post(`${baseUrl}/pdf/split`, formData, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" }),
      );
      setPreviewUrl(url);
      setPreviewPdfFile(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      console.error("Error splitting PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="pdf-utility-container"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <BackButton onBack={pdfFile ? handleBack : null} title="PDF Splitter" />

      {!pdfFile ? (
        <Input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
      ) : (
        <div>{pdfFile.name}</div>
      )}

      {pdfFile && !previewPdfFile && (
        <div>
          <Input
            type="text"
            value={splitPages}
            readOnly
            className="selected-pages-Input"
          />
          <div className="pdf-container">
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-document"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page">
                  <Page pageNumber={index + 1} width={100} />
                  <Input
                    className="page-checkbox"
                    type="checkbox"
                    checked={selectedPages.includes(index)}
                    onChange={() => handlePageSelect(index)}
                  />
                </div>
              ))}
            </Document>
          </div>
          <Button
            onClick={handleSplitPdf}
            disabled={!pdfFile || isLoading}
            className="split-pdf-Button"
          >
            Split PDF
          </Button>
        </div>
      )}

      {previewPdfFile && (
        <div>
          <div className="pdf-container">
            <Document
              file={previewPdfFile}
              onLoadSuccess={onDocumentLoadSuccesspreview}
              className="pdf-document"
            >
              {Array.from(new Array(previewnumPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page">
                  <Page key={index} pageNumber={index + 1} width={100} />
                </div>
              ))}
            </Document>
          </div>
          <Button className="download-pdf-Button" onClick={downloadPdf}>
            Download Split PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default PDFHandler;
