import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, imageUrl }) => {
  return (
    <Helmet>
      <title>{`${title} | Dev Essentials`}</title>
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://devessentials.in/${title.toLowerCase()}`}
      />
      <meta property="og:title" content={`${title} | Dev Essentials`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={`https://devessentials.in/${title.toLowerCase()}`}
      />
      <meta property="twitter:title" content={`${title} | Dev Essentials`} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
export default SEO;
